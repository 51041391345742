export const parseISODate = (isoString: string) => {
  const [date, time] = isoString.split('T');
  const [year, month, day] = date.split('-');
  const [hours, minutes, ms] = time.split('.')[0].split(':');

  return { year, month, day, hours, minutes, ms };
};
export const formatDate = (ISOdate: Date) => {
  if (!ISOdate) {
    return { date: null, time: null };
  }
  const neededDate = new Date(ISOdate).toISOString();

  const { year, month, day, hours, minutes, ms } = parseISODate(neededDate);

  return { date: `${day}.${month}.${year}`, time: `${hours}:${minutes}`, ms };
};

export const dateToISODate = (date: Date | string) => {
  const localDate = new Date(date);
  const utcDate = new Date(
    Date.UTC(
      localDate.getFullYear(),
      localDate.getMonth(),
      localDate.getDate(),
      21,
      0,
      0,
      0,
    ),
  );
  return utcDate.toISOString();
};
