import { mainNavigation } from '../../../constants';
import Account from '../../../assets/icons/account';
import RoundIcon from '../round-icon/round-icon';
import Tabs from '../tabs/tabs';
import styles from './Header.module.css';

function MainHeader(): React.JSX.Element {
  return (
    <div className={styles.wrapper}>
      <div className={styles.appTitle}>Repka</div>
      <div className={styles.tabWrapper}>
        <Tabs
          navigateArr={mainNavigation}
          tabClassName={styles.tabItem}
          activeTabClassName={styles.tabItem__open}
        />
      </div>
      <RoundIcon>
        <Account />
      </RoundIcon>
    </div>
  );
}

export default MainHeader;
