import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { ApproveModal, Checkbox, Select } from '../../../components/shared';
import { envApi } from '../../../api/env';
import { releaseApi } from '../../../api/release';
import { taskApi } from '../../../api/task';
import { HTTP_STATUSES, TOAST_MESSAGES } from '../../../constants';
import Loading from '../../../components/shared/loading/main-loading';
import { IModalActions } from './types';
import ReleaseConflict from './release-conflict';
import styles from '../expired-view/Styles.module.css';

const AssignRelease = ({
  close,
  name = '',
  envId = '',
  getNewDetails,
}: IModalActions) => {
  const [releases, setReleases] = useState<any>([]);
  const [releasesTasks, setReleasesTasks] = useState<any>([]);
  const [tasksLoading, setTasksLoading] = useState(false);
  const [isReleaseConflict, setIsReleaseConflict] = useState(false);

  const getResultAction = (message: string, result: number, status: number) => {
    if (result === status) {
      toast.success(message);
      getNewDetails(true);
      close();
    } else if (result === HTTP_STATUSES.conflict) {
      setIsReleaseConflict(true);
    } else {
      toast.error(TOAST_MESSAGES.COMMON_ERROR);
    }
  };

  const form = useFormik({
    initialValues: {
      releaseId: '',
      overwrite: false,
    },
    onSubmit: async (values) => {
      let response;
      if (!values.releaseId) {
        response = await envApi.unAssigneeReleaseToEnv(envId);
        getResultAction(
          TOAST_MESSAGES.UNASSIGNED,
          response?.status,
          HTTP_STATUSES.ok,
        );
        return;
      }
      const body = {
        releaseId: values.releaseId,
        overwrite: values?.overwrite,
      };
      response = await envApi.assigneeReleaseToEnv(envId, body);
      getResultAction(
        TOAST_MESSAGES.SAVED,
        response?.status,
        HTTP_STATUSES.created,
      );
    },
    validateOnChange: false,
  });

  const getReleasesData = async () => {
    const response = await releaseApi.getReleasesList();
    if (response?.data?.list) {
      const relFromBE = [
        { label: 'No release selected', value: '', planedDate: '' },
      ].concat(
        response?.data?.list?.map((el: any) => {
          return {
            label: el?.releaseNumber,
            value: el?.id,
            planedDate: el?.planedDate,
          };
        }),
      );
      setReleases(relFromBE);
    }
  };
  const getReleaseTasks = async () => {
    setReleasesTasks([]);
    setTasksLoading(true);
    const params: any = {
      skip: 0,
      take: 100,
      sort: 'id',
      order: 'ASC',
      filter: JSON.stringify({ releaseId: form?.values.releaseId }),
    };
    const response = await taskApi.getTasksList(params);
    if (response?.status === HTTP_STATUSES.ok) {
      setReleasesTasks(response?.data?.list);
    }
    setTasksLoading(false);
  };

  useEffect(() => {
    getReleasesData();
  }, []);
  useEffect(() => {
    if (form?.values.releaseId) {
      getReleaseTasks();
    }
  }, [form?.values.releaseId]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue('overwrite', e.target.checked);
  };

  return (
    <>
      {isReleaseConflict ? (
        <ReleaseConflict
          callback={() => {
            form.setFieldValue('overwrite', true);
            form.submitForm();
          }}
          close={close}
        />
      ) : (
        <ApproveModal
          btnText="Apply"
          callback={form.submitForm}
          closeModal={close}
          disabled={form.isSubmitting}
        >
          <div className={styles.editWrapper}>
            <span className={styles.modal__title}>Assign Release</span>
            <div className={styles.description}>
              Select a release for the {name} environment.
            </div>
            <div style={{ marginTop: '16px' }} />
            <Select
              label="Assign release"
              options={releases}
              onChange={(v) => form.setFieldValue('releaseId', v.value)}
              name="releaseId"
              value={form.values.releaseId}
            />
            <div style={{ marginTop: '16px' }} />

            {tasksLoading ? (
              <div className={styles.loading}>
                <Loading />
              </div>
            ) : null}
            {releasesTasks?.length ? (
              <div className={styles.container}>
                <span className={styles.taskTitle}>Task list</span>
                <div className={styles.taskListContainer}>
                  {releasesTasks?.length
                    ? releasesTasks?.map((el: any) => (
                        <span key={el?.id} className={styles.taskItem}>
                          <span className="idTask">#{el?.id}:</span>
                          {el?.name}
                        </span>
                      ))
                    : null}
                </div>
              </div>
            ) : null}
            <div style={{ marginTop: '10px' }} />
            {form?.values.releaseId ? (
              <Checkbox
                label="Overwrite the current task list."
                onChange={handleChange}
                checked={form?.values?.overwrite}
              />
            ) : null}
          </div>
        </ApproveModal>
      )}
    </>
  );
};

export default AssignRelease;
