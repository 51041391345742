import React from 'react';
import { IIcon } from '../../types';
import { colors } from '../../constants/colors';

const ArrowSelectIcon = ({
  color = colors.main_font,
  className,
  onClick = undefined,
}: IIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
      onClick={onClick}
    >
      <path
        d="M4 6L8 10L12 6"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowSelectIcon;
