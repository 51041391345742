// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TextArea_textareaContainer__7UJVc {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.TextArea_textareaLabel__s-Mrc {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: var(--main-font);
}
.TextArea_textarea__jkegD {
  width: 100%;
  min-height: 100px;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid var(--silver);
  outline: none;
  resize: vertical;
  transition:
    border-color 0.3s ease,
    box-shadow 0.3s ease;
}
.TextArea_textarea__jkegD:focus {
  border-color: var(--main-font);
}
.TextArea_textarea__jkegD::placeholder {
  color: var(--silver);
}
.TextArea_textarea__jkegD:disabled {
  background-color: var(--disabled-gray);
  color: var(--silver);
  cursor: not-allowed;
}
.TextArea_helper_text__ubIZs {
  color: var(--icon-gray);
  margin-top: 4px;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/renderer/components/shared/text-aria/TextArea.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,WAAW;AACb;AACA;EACE,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,uBAAuB;AACzB;AACA;EACE,WAAW;EACX,iBAAiB;EACjB,iBAAiB;EACjB,eAAe;EACf,kBAAkB;EAClB,+BAA+B;EAC/B,aAAa;EACb,gBAAgB;EAChB;;wBAEsB;AACxB;AACA;EACE,8BAA8B;AAChC;AACA;EACE,oBAAoB;AACtB;AACA;EACE,sCAAsC;EACtC,oBAAoB;EACpB,mBAAmB;AACrB;AACA;EACE,uBAAuB;EACvB,eAAe;EACf,iBAAiB;EACjB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,WAAW;AACb","sourcesContent":[".textareaContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  width: 100%;\n}\n.textareaLabel {\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 18px;\n  color: var(--main-font);\n}\n.textarea {\n  width: 100%;\n  min-height: 100px;\n  padding: 8px 12px;\n  font-size: 14px;\n  border-radius: 8px;\n  border: 1px solid var(--silver);\n  outline: none;\n  resize: vertical;\n  transition:\n    border-color 0.3s ease,\n    box-shadow 0.3s ease;\n}\n.textarea:focus {\n  border-color: var(--main-font);\n}\n.textarea::placeholder {\n  color: var(--silver);\n}\n.textarea:disabled {\n  background-color: var(--disabled-gray);\n  color: var(--silver);\n  cursor: not-allowed;\n}\n.helper_text {\n  color: var(--icon-gray);\n  margin-top: 4px;\n  text-align: right;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 18px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textareaContainer": `TextArea_textareaContainer__7UJVc`,
	"textareaLabel": `TextArea_textareaLabel__s-Mrc`,
	"textarea": `TextArea_textarea__jkegD`,
	"helper_text": `TextArea_helper_text__ubIZs`
};
export default ___CSS_LOADER_EXPORT___;
