import { useState } from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';

import { envApi } from '../../../api/env';
import { ApproveModal, TextArea } from '../../../components/shared';
import { HTTP_STATUSES, TOAST_MESSAGES } from '../../../constants';
import { IModalActions } from './types';
import styles from '../expired-view/Styles.module.css';

const EditNote = ({ close, envId, task, getNewDetails }: IModalActions) => {
  const [touched, setTouched] = useState(false);
  const noteForm = useFormik({
    initialValues: {
      note: task?.notes || '',
    },
    onSubmit: async (values) => {
      const body = {
        featureId: task?.featureId,
        notes: values?.note,
      };
      const response = await envApi.addNotesForTask(envId, body);
      if (response?.status === HTTP_STATUSES.ok) {
        getNewDetails();
        toast.success(TOAST_MESSAGES.TASK_NOTES);
        close();
      } else {
        toast.error(TOAST_MESSAGES.COMMON_ERROR);
      }
    },
    validateOnChange: false,
  });
  const symbols = noteForm?.values?.note?.length;

  return (
    <ApproveModal
      btnText="Save"
      callback={noteForm.submitForm}
      closeModal={close}
      disabled={noteForm.isSubmitting || !touched}
    >
      <div className={styles.editWrapper}>
        <span className={styles.modal__title}>Task comment</span>
        <div className={styles.description}>
          Enter comment for task #{task?.featureId}: {task?.name}
        </div>
        <TextArea
          placeholder="Enter comment"
          onChange={(e) => {
            noteForm.setFieldValue('note', e?.target?.value);
            setTouched(true);
          }}
          name="note"
          symbols={symbols}
          showCountDown
          maxTextLength={200}
          value={noteForm?.values?.note}
        />
      </div>
    </ApproveModal>
  );
};

export default EditNote;
