export const HTTP_STATUSES = {
  ok: 200,
  created: 201,
  no_content: 204,
  bad_request: 400,
  not_authorized: 401,
  forbidden: 403,
  not_found: 404,
  conflict: 409,
  expired: 410,
  internal_server_error: 500,
  timeout: 502,
  maintenance: 503,
};
