import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import classNames from 'classnames';

import ArrowSelectIcon from '../../../assets/icons/arrow-select';
import { TASK_STATUS } from '../../../screens/Tasks/create-new-task/constants';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { taskApi } from '../../../api/task';
import { colors } from '../../../constants/colors';
import { TOAST_MESSAGES } from '../../../constants';
import styles from './Release.module.css';

function StatusSelect({ id, status }: any): React.JSX.Element {
  const ref = useRef(null);

  const [showListOptions, setShowListOptions] = useState(false);
  const [valueStatus, setValueStatus] = useState(status);

  useOutsideClick(ref, () => setShowListOptions(false));

  useEffect(() => {
    setValueStatus(status);
  }, [status]);

  const onChangeHandler = (v: any) => {
    setValueStatus(v);
    setShowListOptions(false);
    try {
      taskApi.updateTaskStatus(id, { status: v?.value });
      toast.success(TOAST_MESSAGES.SAVED);
    } catch (error) {
      toast.error(TOAST_MESSAGES.CANT_BE_UPDATED);
    }
  };

  const statusClassName = classNames(styles.status, {
    [styles.status__open]: showListOptions,
  });
  const iconClassName = classNames(styles.arrowBtn, {
    [styles.arrowBtn__open]: showListOptions,
  });

  return (
    <div className={styles.statusContainer} ref={ref}>
      <span className={statusClassName}>{valueStatus?.label}</span>
      <ArrowSelectIcon
        className={iconClassName}
        onClick={() => setShowListOptions(!showListOptions)}
        color={showListOptions ? colors.dark_blue : colors.main_font}
      />

      {showListOptions ? (
        <div className={styles.container__options}>
          {TASK_STATUS.map((option: any) => {
            return (
              <span
                className={styles.option}
                key={option.value}
                onClick={() => onChangeHandler(option)}
                role="button"
              >
                {option.label}
              </span>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}

export default StatusSelect;
