import React from 'react';
import className from 'classnames';
import { IOutlinedBtn } from './types';
import AddBtnIcon from '../../../../assets/icons/add-btn-icon';
import styles from './NonBorderBtn.module.css';

const NonBorderBtn = ({
  classNameBtn = '',
  text,
  onClick,
  disabled = false,
  withIcon = true,
}: IOutlinedBtn) => {
  const btnClassName = className(styles.non_border_btn, {
    [classNameBtn]: classNameBtn,
  });

  return (
    <button
      className={btnClassName}
      onClick={
        !disabled
          ? onClick
          : (e) => {
              e.preventDefault();
            }
      }
    >
      {withIcon ? <AddBtnIcon /> : null}
      {text}
    </button>
  );
};

export default NonBorderBtn;
