export const TASK_STATUS = [
  { value: 'NOT_STARTED', label: 'Not started' },
  { value: 'BRS_RECEIVED', label: 'BRs received' },
  { value: 'ANALYSIS', label: 'Analysis' },
  { value: 'DEVELOPMENT', label: 'Development' },
  { value: 'TESTING', label: 'Testing' },
  { value: 'UAT', label: 'UAT' },
  { value: 'READY_TO_RELEASE', label: 'Ready to release' },
  { value: 'RELEASED', label: 'Released' },
];
export enum FeatureStatus {
  NOT_STARTED = 'NOT_STARTED',
  BRS_RECEIVED = 'BRS_RECEIVED',
  ANALYSIS = 'ANALYSIS',
  DEVELOPMENT = 'DEVELOPMENT',
  TESTING = 'TESTING',
  UAT = 'UAT',
  READY_TO_RELEASE = 'READY_TO_RELEASE',
  RELEASED = 'RELEASED',
}
