import classNames from 'classnames';

import { ITable } from './types';
import { colors } from '../../../constants/colors';
import Checkbox from '../check-box/check-box';
import Loading from '../loading/main-loading';

import styles from './Table.module.css';

function Table({
  headers,
  isLoading,
  children,
  isShowCheckbox,
  handleCheckbox,
  addScrollWrapper = false,
}: ITable) {
  const wrapperClassName = classNames(styles.tableWrapper, {
    [styles.scrollWrapper]: addScrollWrapper,
  });
  return (
    <div
      style={
        addScrollWrapper
          ? { background: colors.white, borderRadius: '8px', padding: '4px' }
          : {}
      }
    >
      <div className={wrapperClassName}>
        <table>
          <thead>
            <tr>
              {isShowCheckbox ? (
                <th
                  style={{
                    width: '3%',
                  }}
                >
                  <span
                    style={{
                      display: 'flex',
                      borderRight: `1px solid ${colors.silver}`,
                    }}
                  >
                    <Checkbox onChange={handleCheckbox} />
                  </span>
                </th>
              ) : null}
              {headers.map((header: any, i: number) => {
                const style = {
                  borderRight:
                    headers.length === i + 1
                      ? 'none'
                      : `1px solid ${colors.silver}`,
                  justifyContent: header?.changeFlex,
                };
                return (
                  <th
                    key={header.title}
                    style={{
                      ...(header?.style ? { ...header?.style } : null),
                    }}
                  >
                    <span className={styles.header} style={style}>
                      {header.title}
                    </span>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className={styles.tbody}>
            {isLoading ? (
              <tr>
                <td colSpan={headers.length} className={styles.loader}>
                  <div className={styles.loaderItem}>
                    <Loading />
                  </div>
                </td>
              </tr>
            ) : (
              <>{children}</>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Table;
