export function groupByKey(dataArray: any[], key: string) {
  return dataArray.reduce((acc, item) => {
    const keyValue =
      item[key] !== undefined && item[key] !== null ? item[key] : 'no_release';
    if (!acc[keyValue]) {
      acc[keyValue] = [];
    }
    acc[keyValue].push(item);
    return acc;
  }, {});
}
