import React from 'react';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { INavigation } from '../../../constants/navigation';
import { ITab } from './types';
import styles from './Tabs.module.css';

function Tabs({
  navigateArr,
  activeTabClassName = '',
  tabClassName = '',
}: ITab): React.JSX.Element {
  const location = useLocation();

  const activeTabStyles = activeTabClassName || styles.tabItem__active;
  const tabStyles = classNames(styles.tabItem, tabClassName);

  return (
    <>
      {navigateArr.map((el: INavigation) => {
        const icon = el?.icon || '';

        const isActiveTab = location?.pathname.includes(el.to);
        const tabClassNames = classNames(tabStyles, {
          [activeTabStyles]: isActiveTab,
        });

        return (
          <Link className={tabClassNames} key={el.to} to={el.to}>
            {icon && React.createElement(icon)}
            <span>{el.name}</span>
          </Link>
        );
      })}
    </>
  );
}

export default Tabs;
