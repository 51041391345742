import api from './axios.config';

type List<T = any> = { list: T[]; totalCount: number };

type ProjectResponse = {
  createdAt: string;
  deletedAt: string;
  id: number;
  name: string;
  updatedAt: string;
};

const defaultParams = { skip: 0, take: 10, sort: 'id', order: 'ASC' };

// eslint-disable-next-line import/prefer-default-export
export const getProjectsRequest = (params = defaultParams) =>
  api.get<any | undefined, List<ProjectResponse>>('/projects', { params });

const getProjectsUsers = (params = defaultParams) =>
  api.get<any | undefined>('/project-users', { params });

export const projectsApi = {
  getProjectsUsers,
};
