export function groupByDate(dataArray: any[], itemKey = 'date') {
  const groups = dataArray.reduce((acc, item) => {
    const dateKey = item[itemKey]
      ? new Date(item[itemKey]).toISOString().split('T')[0]
      : 'no_data';

    if (!acc[dateKey]) {
      acc[dateKey] = [];
    }
    acc[dateKey].push(item);
    return acc;
  }, []);

  return Object.entries(groups).map(([group, items]) => ({
    group,
    items,
  }));
}
