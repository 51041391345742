// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  scroll-behavior: smooth;
}
body {
  position: relative;
  color: var(--white);
  min-height: 100vh;
  font-family: 'Work Sans';
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  margin: 0;
}

#root {
  height: 100%;
}
.row {
  display: flex;
}
.col4 {
  display: flex;
}
.col2 {
  display: flex;
}
.idTask {
  color: var(--main-font);
  white-space: nowrap;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin-right: 2px;
  width: max-content;
}
`, "",{"version":3,"sources":["webpack://./src/renderer/styles/global.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;AACA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,iBAAiB;EACjB,wBAAwB;EACxB,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,cAAc;EACd,SAAS;AACX;;AAEA;EACE,YAAY;AACd;AACA;EACE,aAAa;AACf;AACA;EACE,aAAa;AACf;AACA;EACE,aAAa;AACf;AACA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":["html {\n  scroll-behavior: smooth;\n}\nbody {\n  position: relative;\n  color: var(--white);\n  min-height: 100vh;\n  font-family: 'Work Sans';\n  overflow-y: hidden;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  overflow: auto;\n  margin: 0;\n}\n\n#root {\n  height: 100%;\n}\n.row {\n  display: flex;\n}\n.col4 {\n  display: flex;\n}\n.col2 {\n  display: flex;\n}\n.idTask {\n  color: var(--main-font);\n  white-space: nowrap;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 18px;\n  margin-right: 2px;\n  width: max-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
