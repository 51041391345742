// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NonBorderBtn_non_border_btn__93xOn {
  align-items: center;
  align-self: center;
  background: transparent;
  border: 1px solid transparent;
  color: var(--main-blue);
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  gap: 8px;
  justify-content: center;
  line-height: 28px;
  min-width: 90px;
  padding: 4px 8px;
  text-align: center;
}

.NonBorderBtn_non_border_btn__93xOn:hover,
.NonBorderBtn_non_border_btn__93xOn:hover > svg > path {
  color: var(--dark-blue);
  stroke: var(--dark-blue);
}
`, "",{"version":3,"sources":["webpack://./src/renderer/components/shared/buttons/NonBorderBtn/NonBorderBtn.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,uBAAuB;EACvB,6BAA6B;EAC7B,uBAAuB;EACvB,eAAe;EACf,aAAa;EACb,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,QAAQ;EACR,uBAAuB;EACvB,iBAAiB;EACjB,eAAe;EACf,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;;EAEE,uBAAuB;EACvB,wBAAwB;AAC1B","sourcesContent":[".non_border_btn {\n  align-items: center;\n  align-self: center;\n  background: transparent;\n  border: 1px solid transparent;\n  color: var(--main-blue);\n  cursor: pointer;\n  display: flex;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  gap: 8px;\n  justify-content: center;\n  line-height: 28px;\n  min-width: 90px;\n  padding: 4px 8px;\n  text-align: center;\n}\n\n.non_border_btn:hover,\n.non_border_btn:hover > svg > path {\n  color: var(--dark-blue);\n  stroke: var(--dark-blue);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"non_border_btn": `NonBorderBtn_non_border_btn__93xOn`
};
export default ___CSS_LOADER_EXPORT___;
