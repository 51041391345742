const HR = ({ color = '#EFF1F4', margin = '14px 0' }: any) => {
  return (
    <div
      style={{
        background: color,
        height: '1px',
        margin,
        width: '100%',
      }}
    />
  );
};
export default HR;
