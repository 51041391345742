const Confluence = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.47721 13.4379C1.31268 13.7062 1.1279 14.0176 0.97096 14.2656C0.903435 14.3798 0.883372 14.5158 0.915085 14.6445C0.946798 14.7733 1.02775 14.8845 1.14055 14.9541L4.43118 16.9791C4.48836 17.0144 4.552 17.038 4.61838 17.0485C4.68477 17.0589 4.75257 17.0561 4.81783 17.04C4.8831 17.024 4.94452 16.9952 4.99851 16.9552C5.05251 16.9151 5.098 16.8648 5.13234 16.807C5.26396 16.5868 5.43355 16.3008 5.61834 15.9945C6.92193 13.8429 8.23312 14.1062 10.5973 15.2351L13.8601 16.7868C13.9212 16.8159 13.9876 16.8324 14.0553 16.8354C14.123 16.8384 14.1906 16.8278 14.2541 16.8042C14.3176 16.7806 14.3757 16.7445 14.425 16.698C14.4742 16.6515 14.5137 16.5956 14.541 16.5336L16.1078 12.9899C16.161 12.8682 16.1643 12.7306 16.117 12.6065C16.0696 12.4825 15.9754 12.382 15.8547 12.3267C15.1662 12.0027 13.7968 11.3572 12.5641 10.7624C8.12933 8.6083 4.3603 8.74752 1.47721 13.4379Z"
        fill="url(#paint0_linear_1132_6776)"
      />
      <path
        d="M16.2203 5.39613C16.3848 5.12782 16.5696 4.81648 16.7265 4.56841C16.7941 4.4543 16.8141 4.31826 16.7824 4.18951C16.7507 4.06077 16.6697 3.94961 16.5569 3.87991L13.2663 1.85491C13.2087 1.81611 13.1437 1.78958 13.0754 1.77697C13.0071 1.76436 12.937 1.76594 12.8693 1.78161C12.8016 1.79728 12.7379 1.8267 12.6821 1.86806C12.6263 1.90941 12.5796 1.9618 12.5449 2.02198C12.4133 2.2422 12.2437 2.52823 12.0589 2.83451C10.7553 4.98607 9.44413 4.72282 7.07994 3.59388L3.82728 2.04982C3.76612 2.02071 3.69973 2.00417 3.63206 2.00118C3.56439 1.99819 3.4968 2.00881 3.43331 2.03241C3.36982 2.05601 3.3117 2.09212 3.26242 2.13859C3.21313 2.18506 3.17367 2.24095 3.14638 2.30295L1.57953 5.8467C1.52633 5.96835 1.52304 6.10604 1.57039 6.23009C1.61774 6.35414 1.71193 6.45462 1.83266 6.50988C2.52116 6.83388 3.89057 7.47935 5.12328 8.0742C9.56816 10.2258 13.3372 10.0815 16.2203 5.39613Z"
        fill="url(#paint1_linear_1132_6776)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1132_6776"
          x1="16.0192"
          y1="18.0245"
          x2="6.06383"
          y2="12.3039"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.18" stopColor="#0052CC" />
          <stop offset="1" stopColor="#2684FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1132_6776"
          x1="1.67825"
          y1="0.806977"
          x2="11.6362"
          y2="6.53013"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.18" stopColor="#0052CC" />
          <stop offset="1" stopColor="#2684FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Confluence;
