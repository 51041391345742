import { useCallback } from 'react';
import { toast } from 'react-toastify';

import { envApi } from '../../../api/env';
import { DangerModal } from '../../../components/shared';
import { HTTP_STATUSES, TOAST_MESSAGES } from '../../../constants';
import { IModalActions } from './types';

const CleanAll = ({ close, getNewDetails, envId, name }: IModalActions) => {
  const handleCleanEnv = useCallback(async () => {
    const response = await envApi.clearEnv(envId);
    if (response?.status === HTTP_STATUSES.ok) {
      getNewDetails(true);
      toast.success(TOAST_MESSAGES.CLEANED);
      close();
    } else {
      toast.error(TOAST_MESSAGES.COMMON_ERROR);
    }
  }, []);
  return (
    <DangerModal
      callback={handleCleanEnv}
      closeModal={close}
      text={`Are you sure you want to delete all tasks from ${name} environment?`}
      title="Clean All"
    />
  );
};

export default CleanAll;
