// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MoveTaskDetails_containerDetails__-snp5 {
  display: flex;
  justify-content: space-between;
  margin-right: 16px;
  width: 100%;
}
.MoveTaskDetails_checkBoxContainer__5fVu3 {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 8px;
}
.MoveTaskDetails_searchRow__7MW8Y {
  color: var(--main-font);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  width: 100%;
}
.MoveTaskDetails_defaultEnvContainer__7Brdr {
  align-items: center;
  display: flex;
  justify-content: space-between;
  color: var(--main-font);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border-radius: 8px;
  border: 1px solid var(--main-font);
  padding: 0 12px;
  min-width: 100%;
  width: max-content;
  height: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/renderer/screens/EnvDashboard/components/move-selected-tasks/MoveTaskDetails.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,kBAAkB;EAClB,WAAW;AACb;AACA;EACE,aAAa;EACb,2BAA2B;EAC3B,sBAAsB;EACtB,YAAY;AACd;AACA;EACE,uBAAuB;EACvB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,WAAW;AACb;AACA;EACE,mBAAmB;EACnB,aAAa;EACb,8BAA8B;EAC9B,uBAAuB;EACvB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,kCAAkC;EAClC,eAAe;EACf,eAAe;EACf,kBAAkB;EAClB,YAAY;AACd","sourcesContent":[".containerDetails {\n  display: flex;\n  justify-content: space-between;\n  margin-right: 16px;\n  width: 100%;\n}\n.checkBoxContainer {\n  display: flex;\n  justify-content: flex-start;\n  flex-direction: column;\n  padding: 8px;\n}\n.searchRow {\n  color: var(--main-font);\n  font-size: 15px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 20px;\n  width: 100%;\n}\n.defaultEnvContainer {\n  align-items: center;\n  display: flex;\n  justify-content: space-between;\n  color: var(--main-font);\n  font-size: 13px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 20px;\n  border-radius: 8px;\n  border: 1px solid var(--main-font);\n  padding: 0 12px;\n  min-width: 100%;\n  width: max-content;\n  height: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerDetails": `MoveTaskDetails_containerDetails__-snp5`,
	"checkBoxContainer": `MoveTaskDetails_checkBoxContainer__5fVu3`,
	"searchRow": `MoveTaskDetails_searchRow__7MW8Y`,
	"defaultEnvContainer": `MoveTaskDetails_defaultEnvContainer__7Brdr`
};
export default ___CSS_LOADER_EXPORT___;
