// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Table_tableWrapper__wAMym {
  background-color: var(--white);
  border-radius: 8px;
  padding: 4px;
  width: 100%;
}
.Table_scrollWrapper__KV3\\+D {
  padding: 0;
  max-height: 500px;
  overflow-y: auto;
}
table {
  border-collapse: collapse;
  border-radius: 8px;
  width: 100%;
}
thead tr {
  border-radius: 4px;
  background-color: var(--light-background);
  padding: 4px;
}
thead th {
  background-color: var(--light-background);
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 16px;
  padding-right: 0;
}
.Table_header__CN-S6 {
  color: var(--main-font);
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  padding-right: 16px;
}

thead th:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
thead th:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
tbody > tr:last-child {
  border: none;
}
.Table_loaderItem__cS9iK {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 50px;
}
`, "",{"version":3,"sources":["webpack://./src/renderer/components/shared/table/Table.module.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,kBAAkB;EAClB,YAAY;EACZ,WAAW;AACb;AACA;EACE,UAAU;EACV,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,WAAW;AACb;AACA;EACE,kBAAkB;EAClB,yCAAyC;EACzC,YAAY;AACd;AACA;EACE,yCAAyC;EACzC,gBAAgB;EAChB,MAAM;EACN,UAAU;EACV,aAAa;EACb,gBAAgB;AAClB;AACA;EACE,uBAAuB;EACvB,aAAa;EACb,2BAA2B;EAC3B,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,2BAA2B;EAC3B,8BAA8B;AAChC;AACA;EACE,4BAA4B;EAC5B,+BAA+B;AACjC;AACA;EACE,YAAY;AACd;AACA;EACE,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,YAAY;AACd","sourcesContent":[".tableWrapper {\n  background-color: var(--white);\n  border-radius: 8px;\n  padding: 4px;\n  width: 100%;\n}\n.scrollWrapper {\n  padding: 0;\n  max-height: 500px;\n  overflow-y: auto;\n}\ntable {\n  border-collapse: collapse;\n  border-radius: 8px;\n  width: 100%;\n}\nthead tr {\n  border-radius: 4px;\n  background-color: var(--light-background);\n  padding: 4px;\n}\nthead th {\n  background-color: var(--light-background);\n  position: sticky;\n  top: 0;\n  z-index: 1;\n  padding: 16px;\n  padding-right: 0;\n}\n.header {\n  color: var(--main-font);\n  display: flex;\n  justify-content: flex-start;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 18px;\n  padding-right: 16px;\n}\n\nthead th:first-child {\n  border-top-left-radius: 4px;\n  border-bottom-left-radius: 4px;\n}\nthead th:last-child {\n  border-top-right-radius: 4px;\n  border-bottom-right-radius: 4px;\n}\ntbody > tr:last-child {\n  border: none;\n}\n.loaderItem {\n  align-items: center;\n  display: flex;\n  justify-content: center;\n  height: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableWrapper": `Table_tableWrapper__wAMym`,
	"scrollWrapper": `Table_scrollWrapper__KV3+D`,
	"header": `Table_header__CN-S6`,
	"loaderItem": `Table_loaderItem__cS9iK`
};
export default ___CSS_LOADER_EXPORT___;
