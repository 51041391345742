import { useRef, useState } from 'react';
import classNames from 'classnames';

import { colors } from '../../../constants/colors';
import ArrowSelectIcon from '../../../assets/icons/arrow-select';
import useOutsideClick from '../../../hooks/useOutsideClick';
import ClearInputIcon from '../../../assets/icons/clear-input';
import { ISelect } from './types';
import styles from './Select.module.css';

const showClearExclusionValue = 'All';

const Select = ({
  id,
  name,
  options,
  onChange,
  value,
  label,
  error,
  isSubmitted,
  emptyText = 'Select...',
  disabled = false,
  lightBorder = true,
  height = '40px',
  isComplexInputPart = false,
  externalFocus = false,
  bottom = 'auto',
  initialValue = '',
  additionalClassNameForTitle = '',
  showClear = false,
  showAdditionalInfoInSelect = false,
  additionalInfoText = 'Assigned: ',
}: ISelect) => {
  const ref = useRef(null);
  const [showListOptions, setShowListOptions] = useState(false);

  const onChangeHandler = (v: any) => {
    onChange(v);
    handleCloseOptions();
  };

  const switchListOption = (e: any) => {
    e.stopPropagation();
    if (disabled) return;
    setShowListOptions(!showListOptions);
  };

  const handleCloseOptions = () => {
    setShowListOptions(false);
  };

  const iconClassName = classNames(styles.icon, {
    [styles.icon__open]: showListOptions,
  });

  const titleClassName = classNames(styles.container__title, {
    [styles.container__title__complex]: isComplexInputPart,
    [styles.container__title__error]: error || isSubmitted,
    [additionalClassNameForTitle]: additionalClassNameForTitle,
  });

  const selectedValue =
    options?.filter((option: any) => option.value === value)[0]?.label ||
    '' ||
    options?.filter((option: any) => option.value === initialValue)[0]?.label;

  useOutsideClick(ref, handleCloseOptions);

  return (
    <>
      <div
        ref={ref}
        className={styles.container}
        id={id || name}
        onClick={switchListOption}
        role="button"
      >
        {label && <div className={styles.label}>{label}</div>}
        <div
          className={titleClassName}
          style={{
            borderColor: !lightBorder
              ? 'var(--inputBorderColor)'
              : externalFocus
                ? colors.medium_gray
                : '',
            height,
          }}
        >
          {selectedValue ? (
            <span>
              {showAdditionalInfoInSelect ? additionalInfoText : ''}{' '}
              {selectedValue}
            </span>
          ) : (
            <span className={styles.placeholder}>
              {showAdditionalInfoInSelect ? additionalInfoText : ''} {emptyText}
            </span>
          )}
          {showClear ? (
            <>
              {selectedValue && selectedValue !== showClearExclusionValue ? (
                <ClearInputIcon
                  className={styles.clearIcon}
                  onClick={() => onChange(null)}
                />
              ) : (
                <ArrowSelectIcon
                  color={showListOptions ? colors.dark_blue : colors.main_font}
                  className={iconClassName}
                />
              )}
            </>
          ) : (
            <ArrowSelectIcon
              color={showListOptions ? colors.dark_blue : colors.main_font}
              className={iconClassName}
            />
          )}
        </div>

        {showListOptions ? (
          <div className={styles.container__options} style={{ bottom }}>
            {options.map((option: any) => {
              const isActive = value === option.value;
              const isSubtitleOption = option.value === 'subtitle';
              return (
                <>
                  {isSubtitleOption ? (
                    <span className={styles.subtitle}>{option.label}</span>
                  ) : (
                    <span
                      className={styles.option}
                      key={option.value}
                      onClick={() => onChangeHandler(option)}
                      style={{
                        background: isActive ? colors.light_blue : '',
                        color: isActive ? colors.dark_blue : '',
                      }}
                      role="button"
                    >
                      {option.label}
                    </span>
                  )}
                </>
              );
            })}
          </div>
        ) : null}
      </div>
      {error ? <div className={styles.error}> {error} </div> : null}
    </>
  );
};

export default Select;
