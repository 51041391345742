import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { loginGoogleRequest } from '../../api/auth';
import { setAccessToken, setProjectId } from '../../utils/localStorage';
import { getProjectsRequest } from '../../api/projects';
import { useUserQuery } from '../../hooks/useQuery';
import PageWrapper from '../../components/layouts/PageWrapper/page-wrapper';
import { RoutesData } from '../../types/routes/routes';

import styles from './Login.module.css';
function Login() {
  const navigate = useNavigate();
  const { refetch: refetchUser } = useUserQuery();

  const { mutate: getProjects } = useMutation({
    mutationFn: getProjectsRequest,
    onSuccess: (data) => {
      setProjectId(data.list[0]?.id.toString() || '1');
      refetchUser();
      navigate(RoutesData.Planning, { replace: true });
    },
    onError: (error) => {
      // eslint-disable-next-line
      console.error('Error fetching data:', error);
      refetchUser();
      setProjectId('1');
      navigate(RoutesData.Planning, { replace: true });
    },
  });

  const { mutate: loginGoogle } = useMutation({
    mutationFn: loginGoogleRequest,
    onSuccess: (data) => {
      setAccessToken(data?.data.token);
      getProjects(undefined);
    },
    onError: (error) => {
      // eslint-disable-next-line
      console.error('Error fetching data:', error);
    },
  });

  const handleSuccess = async (cr: CredentialResponse) => loginGoogle(cr);
  const handleError = () => {
    // eslint-disable-next-line
    console.log('Login Failed');
  };

  return (
    <PageWrapper>
      <div className={styles.wrapper}>
        <div className={styles.appTitle}>Repka</div>
        <span className={styles.welcomeMessage}>
          Welcome to Repka Task Manager, sign in <br /> and enjoy with us.
        </span>
        <GoogleLogin onSuccess={handleSuccess} onError={handleError} />
      </div>
    </PageWrapper>
  );
}

export default Login;
