import React from 'react';
import { colors } from '../../../constants/colors';
import { IIcon } from '../../../types';

const SDLCIcon = ({ color = colors.icon_gray }: IIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14.6673 6H1.33398M4.00065 11.6667L5.66732 10L4.00065 8.33333M7.33398 11.6667H10.0007M1.33398 5.2V10.8C1.33398 11.9201 1.33398 12.4801 1.55197 12.908C1.74372 13.2843 2.04968 13.5903 2.426 13.782C2.85382 14 3.41388 14 4.53398 14H11.4673C12.5875 14 13.1475 14 13.5753 13.782C13.9516 13.5903 14.2576 13.2843 14.4493 12.908C14.6673 12.4801 14.6673 11.9201 14.6673 10.8V5.2C14.6673 4.07989 14.6673 3.51985 14.4493 3.09202C14.2576 2.71569 13.9516 2.40973 13.5753 2.21799C13.1475 2 12.5875 2 11.4673 2H4.53398C3.41388 2 2.85382 2 2.426 2.21799C2.04968 2.40973 1.74372 2.71569 1.55197 3.09202C1.33398 3.51984 1.33398 4.07989 1.33398 5.2Z"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SDLCIcon;
