import React from 'react';
import { IIcon } from '../../types';
import { colors } from '../../constants/colors';

const ExitIcon = ({
  className,
  onClick = undefined,
  color = colors.main_font,
}: IIcon) => {
  return (
    <svg
      onClick={onClick}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M18 6L6 18M6 6L18 18"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ExitIcon;
