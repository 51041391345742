export const cutStringByLength = (
  string: string | undefined,
  start: number,
  end: number,
  maxLength?: number,
) => {
  if (!string) return '';
  if (string.length > (maxLength || 16)) {
    return `${string?.slice(start, end)}...`;
  } else return string;
};
