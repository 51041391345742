import React from 'react';
import { colors } from '../../../constants/colors';
import { IIcon } from '../../../types';

const EstimateIcon = ({ color = colors.icon_gray }: IIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M3.83398 2L1.83398 4M15.1673 4L13.1673 2M4.50065 12.6667L3.16732 14M12.5007 12.6667L13.834 14M8.50065 6V8.66667L9.83398 10M8.50065 14C9.91512 14 11.2717 13.4381 12.2719 12.4379C13.2721 11.4377 13.834 10.0811 13.834 8.66667C13.834 7.2522 13.2721 5.89563 12.2719 4.89543C11.2717 3.89523 9.91512 3.33333 8.50065 3.33333C7.08616 3.33333 5.72961 3.89523 4.72942 4.89543C3.72922 5.89563 3.16732 7.2522 3.16732 8.66667C3.16732 10.0811 3.72922 11.4377 4.72942 12.4379C5.72961 13.4381 7.08616 14 8.50065 14Z"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EstimateIcon;
