import { useLocation } from 'react-router-dom';
import { useState } from 'react';

import ContentPageContainer from '../../components/layouts/ContentPageContainer/content-page-container';
import { PageHeader } from '../../components/shared';
import { taskNavigation, TASKS_TABS } from './navigation';
import { RoutesData } from '../../types/routes/routes';
import { CreateNewTask } from '../../screens/Tasks';

function Tasks() {
  const location = useLocation();
  const [searchValue, setSearchValue] = useState('');
  const [searchByString, setSearchByString] = useState(false);

  const currentTab =
    TASKS_TABS.find((el) => location.pathname.includes(el.path)) ||
    TASKS_TABS[0];
  const Component = currentTab?.content;
  const isNewTaskScreen = location.pathname.includes(RoutesData.CreateTask);

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <PageHeader
        navigateArr={taskNavigation}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        searchByString={searchByString}
        setSearchByString={setSearchByString}
      />
      {isNewTaskScreen ? (
        <CreateNewTask />
      ) : (
        <ContentPageContainer>
          {Component && (
            <Component search={searchValue} searchByString={searchByString} />
          )}
        </ContentPageContainer>
      )}
    </div>
  );
}

export default Tasks;
