import React from 'react';
import className from 'classnames';
import { IPrimaryBtn } from './types';
import styles from './PrimaryBtn.module.css';

const PrimaryBtn = ({
  classNameBtn = '',
  text,
  onClick,
  disabled = false,
}: IPrimaryBtn) => {
  const btnClassName = className(styles.primary_btn, {
    [classNameBtn]: classNameBtn,
    [styles.primary_btn_disabled]: disabled,
  });

  return (
    <button
      className={btnClassName}
      onClick={
        !disabled
          ? onClick
          : (e) => {
              e.preventDefault();
            }
      }
    >
      {text}
    </button>
  );
};

export default PrimaryBtn;
