export const colors = {
  main_blue: '#205dd1',
  dark_blue: '#0641B1',
  light_blue: '#f8fafb',
  main_font: '#0b1215',
  disabled_font: '#7b9098',
  danger: '#d12020',
  dark_danger: '#b20505',
  element_background: '#f9e6e6',
  light_gray: '#f0f0f0',
  light_background: '#eff1f4',
  medium_gray: '#b9b9b9',
  icon_gray: '#8e9fa7',
  silver: '#c4d1d7',
  white: '#fff',
};
