import React, { memo } from 'react';
import { Notification } from '../../../components/shared';
import {
  AddTask,
  AssignRelease,
  CleanAll,
  EditDeploymentStatus,
  MoveRelease,
  Restore,
} from './index';

type ModalsState = {
  showAddTaskModal: boolean;
  showReleaseModal: boolean;
  showEditDescEnv: boolean;
  showRestore: boolean;
  showSuccessSnapshot: boolean;
  showCleanAll: boolean;
  showMoveRelease: boolean;
};

type IModalsManager = {
  modals: ModalsState;
  envDetails: any;
  setModals: (state: any) => void;
  getTaskList: () => void;
  getEnvDetails: () => void;
  isWaitingAction?: boolean;
  envs?: any;
  isProdEnv?: boolean;
  setUpdateData?: (state: boolean) => void;
  moveReleaseForm?: any;
};

const ModalsManager = ({
  modals,
  envDetails,
  setModals,
  getTaskList,
  isWaitingAction,
  getEnvDetails,
  envs,
  isProdEnv,
  setUpdateData,
  moveReleaseForm,
}: IModalsManager) => {
  return (
    <>
      {modals.showAddTaskModal ? (
        <AddTask
          close={() =>
            setModals((prev: any) => ({ ...prev, showAddTaskModal: false }))
          }
          envId={envDetails?.id}
          name={envDetails?.name}
          getNewDetails={getTaskList}
          isWaitingAction={isWaitingAction}
        />
      ) : null}
      {modals.showReleaseModal ? (
        <AssignRelease
          close={() =>
            setModals((prev: any) => ({ ...prev, showReleaseModal: false }))
          }
          envId={envDetails?.id}
          name={envDetails?.name}
          getNewDetails={getEnvDetails}
        />
      ) : null}
      {modals.showEditDescEnv ? (
        <EditDeploymentStatus
          close={() =>
            setModals((prev: any) => ({ ...prev, showEditDescEnv: false }))
          }
          envId={envDetails?.id}
          name={envDetails?.name}
          initialStatus={envDetails?.description}
          getNewDetails={getEnvDetails}
        />
      ) : null}
      {modals.showRestore ? (
        <Restore
          close={() =>
            setModals((prev: any) => ({ ...prev, showRestore: false }))
          }
          envId={envDetails?.id}
          envs={envs}
          getNewDetails={getEnvDetails}
          isProdEnv={isProdEnv}
        />
      ) : null}
      {modals.showCleanAll ? (
        <CleanAll
          close={() =>
            setModals((prev: any) => ({ ...prev, showCleanAll: false }))
          }
          envId={envDetails?.id}
          name={envDetails?.name}
          getNewDetails={getEnvDetails}
        />
      ) : null}
      {modals.showSuccessSnapshot ? (
        <Notification
          message={`The ${envDetails?.name} environment snapshot was created.`}
          onClose={() =>
            setModals((prev: any) => ({ ...prev, showSuccessSnapshot: false }))
          }
        />
      ) : null}
      {modals.showMoveRelease ? (
        <MoveRelease
          close={() =>
            setModals((prev: any) => ({ ...prev, showMoveRelease: false }))
          }
          envId=""
          name={envDetails?.name}
          getNewDetails={getEnvDetails}
          release={envDetails?.release?.releaseNumber}
          releaseId={envDetails?.release?.id}
          setUpdateData={setUpdateData}
          moveReleaseForm={moveReleaseForm}
        />
      ) : null}
    </>
  );
};

export default memo(ModalsManager);
