import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { ModalWrapperProps } from './types';
import './modal-wrapper.css';

const ModalWrapper: React.FC<ModalWrapperProps> = ({
  children,
  index = 8,
  style,
}) => {
  useEffect(() => {
    let modalRoot = document.getElementById('modal');
    if (!modalRoot) {
      modalRoot = document.createElement('div');
      modalRoot.id = 'modal';
      document.body.appendChild(modalRoot);
    }

    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const modalRoot = document.getElementById('modal');
  if (!modalRoot) {
    console.error('Element with id-modal not found in the DOM.');
    return null;
  }

  return ReactDOM.createPortal(
    <div style={{ zIndex: index, ...style }} className="modal__wrapper">
      {children}
    </div>,
    modalRoot,
  );
};

export default ModalWrapper;
