// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DangerBtn_danger__btn__SXjNS {
  align-items: center;
  align-self: center;
  background: var(--danger);
  border-radius: 8px;
  border: none;
  color: var(--white);
  cursor: pointer;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  justify-content: center;
  line-height: 18px;
  min-width: 90px;
  padding: 10px 24px;
}
.DangerBtn_danger__btn__SXjNS:hover {
  background: var(--dark-danger);
}

.DangerBtn_disabled__danger__btn__jjWpg {
  background: var(--light-gray);
  color: var(--medium-gray);
  cursor: not-allowed;
}
.DangerBtn_disabled__danger__btn__jjWpg:hover {
  background: var(--light-gray);
  color: var(--medium-gray);
}

`, "",{"version":3,"sources":["webpack://./src/renderer/components/shared/buttons/DangerButton/DangerBtn.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,uBAAuB;EACvB,iBAAiB;EACjB,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,8BAA8B;AAChC;;AAEA;EACE,6BAA6B;EAC7B,yBAAyB;EACzB,mBAAmB;AACrB;AACA;EACE,6BAA6B;EAC7B,yBAAyB;AAC3B","sourcesContent":[".danger__btn {\n  align-items: center;\n  align-self: center;\n  background: var(--danger);\n  border-radius: 8px;\n  border: none;\n  color: var(--white);\n  cursor: pointer;\n  font-size: 15px;\n  font-style: normal;\n  font-weight: 500;\n  justify-content: center;\n  line-height: 18px;\n  min-width: 90px;\n  padding: 10px 24px;\n}\n.danger__btn:hover {\n  background: var(--dark-danger);\n}\n\n.disabled__danger__btn {\n  background: var(--light-gray);\n  color: var(--medium-gray);\n  cursor: not-allowed;\n}\n.disabled__danger__btn:hover {\n  background: var(--light-gray);\n  color: var(--medium-gray);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"danger__btn": `DangerBtn_danger__btn__SXjNS`,
	"disabled__danger__btn": `DangerBtn_disabled__danger__btn__jjWpg`
};
export default ___CSS_LOADER_EXPORT___;
