import { memo } from 'react';

import { Table } from '../../../components/shared';
import WaitingItem from './waiting-item';
import { TaskProps } from './types';

import styles from './Styles.module.css';

const WaitingListTaskTable = ({
  taskList,
  isMultipleAction,
  envID,
  getNewDetails,
  handleChange,
  isLoading,
  handleAllChange,
  selectedTasks,
  isProdEnv = false,
}: TaskProps) => {
  const headersWaiting = isProdEnv
    ? [
        { title: 'Task' },
        { title: 'Comment', style: { width: '40%' } },
        { title: 'Prio', style: { width: '8%' } },
        { title: 'Modules', style: { width: '5%' }, changeFlex: 'center' },
      ]
    : [
        { title: 'Task' },
        { title: 'Comment', style: { width: '40%' } },
        { title: 'Prio', style: { width: '8%' } },
      ];

  return (
    <Table
      headers={headersWaiting}
      isShowCheckbox={isMultipleAction}
      isLoading={isLoading}
      handleCheckbox={
        handleAllChange
          ? (e: React.ChangeEvent<HTMLInputElement>) =>
              handleAllChange(e, 'waiting')
          : undefined
      }
    >
      {taskList?.length ? (
        taskList.map((el: any) => (
          <WaitingItem
            key={el?.id}
            item={el}
            isMultipleAction={isMultipleAction}
            envId={envID}
            getNewDetails={getNewDetails}
            handleChange={handleChange}
            selectedTasks={selectedTasks}
            isProdEnv={isProdEnv}
          />
        ))
      ) : (
        <tr>
          <td colSpan={8} className={styles.noData}>
            No data
          </td>
        </tr>
      )}
    </Table>
  );
};

export default memo(WaitingListTaskTable);
