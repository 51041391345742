import React from 'react';
import { ITextArea } from './types';

import styles from './TextArea.module.css';

const TextArea: React.FC<ITextArea> = ({
  label,
  symbols,
  maxTextLength = 100,
  showCountDown = false,
  ...props
}) => {
  const countDown = `${String(symbols)}/${maxTextLength}`;

  return (
    <div className={styles.textareaContainer}>
      {label && <label className={styles.textareaLabel}>{label}</label>}
      <textarea
        className={styles.textarea}
        maxLength={maxTextLength}
        {...props}
      />
      {showCountDown ? (
        <div className={styles.helper_text}>{countDown}</div>
      ) : null}
    </div>
  );
};

export default TextArea;
