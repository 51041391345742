import React, { useEffect, useState } from 'react';
import TripleTrueIcon from '../../../assets/icons/tripleTrue';
import { colors } from '../../../constants/colors';
import { formatDate } from '../../../utils';
import styles from './Notification.module.css';

type INotification = {
  message: string | React.ReactNode;
  onClose?: () => void;
  showDate?: boolean;
};

const Notification: React.FC<INotification> = ({
  message,
  onClose,
  showDate = false,
}) => {
  const date = formatDate(new Date());
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      if (onClose) onClose();
    }, 5000);

    return () => clearTimeout(timer);
  }, [onClose]);

  if (!isVisible) return null;

  return (
    <div className={styles.notification}>
      <div className={styles.done}>
        <TripleTrueIcon color={colors.white} />
      </div>

      <div className={styles.message}>
        <span className={styles.messageText}>{message}</span>
        {showDate && (
          <span className={styles.date}>
            {date?.date} {date?.time}
          </span>
        )}
      </div>
      <button
        className={styles.closeButton}
        onClick={() => {
          setIsVisible(false);
          if (onClose) onClose();
        }}
      >
        &times;
      </button>
    </div>
  );
};

export default Notification;
