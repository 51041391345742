import { RoutesData } from '../../types/routes/routes';
import PlanningIcon from '../../assets/icons/navigation-icons/planning';
import EstimateIcon from '../../assets/icons/navigation-icons/estimate';
import SDLCIcon from '../../assets/icons/navigation-icons/sdlc';
import { PlanningTable, SDLCTable } from '../../screens/Tasks';
import EstimateTable from '../../screens/Tasks/estimate/estimate';

export type INavigation = {
  name: string;
  to: string;
  icon?: React.ComponentType;
};
export const taskNavigation: INavigation[] = [
  {
    name: 'Planning',
    to: RoutesData.Planning,
    icon: PlanningIcon,
  },
  {
    name: 'Estimate & Fact',
    to: RoutesData.Estimate,
    icon: EstimateIcon,
  },
  {
    name: 'SDLC',
    to: RoutesData.SDLC,
    icon: SDLCIcon,
  },
];

export const TASKS_TABS = [
  { path: RoutesData.Planning, content: PlanningTable },
  { path: RoutesData.Estimate, content: EstimateTable },
  { path: RoutesData.SDLC, content: SDLCTable },
];
