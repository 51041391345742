import api from './axios.config';

type EnvResponse = any;

const getEnvsForProject = (id: number | string) =>
  api.get<any, EnvResponse>(`/projects/${id}`);

const getEnvsTasks = (id: number | string, params: any) => {
  return api.get<any, EnvResponse>(`/environments/${id}/features`, { params });
};
const getEnvDetails = (id: number | string) => {
  return api.get<any, EnvResponse>(`/environments/${id}`);
};
const updateEnvDescription = (
  id: number | string,
  body: {
    description: string;
  },
) => {
  return api.patch<any, EnvResponse>(`/environments/${id}`, body);
};
const clearEnv = (envId: number | string) => {
  return api.put<any, EnvResponse>(`/environments/${envId}/clean`);
};
const assigneeReleaseToEnv = (
  envId: number | string,
  body: {
    releaseId: number | string;
    overwrite: boolean;
  },
) => {
  return api.post<any, EnvResponse>(`/environments/${envId}/releases`, body);
};
const unAssigneeReleaseToEnv = (envId: number | string) => {
  return api.delete<any, EnvResponse>(`/environments/${envId}/releases`);
};
const removeTasksFromEnv = (
  envId: number | string,
  body: {
    featureIds: any;
  },
) => {
  return api.post<any, EnvResponse>(
    `/environments/${envId}/features/delete`,
    body,
  );
};
const assigneeTaskToEnv = (envId: number | string, body: any) => {
  return api.post<any, EnvResponse>(`/environments/${envId}/features`, body);
};

const assigneeReleaseAndTasks = (
  envId: number | string,
  body: {
    releaseId: number | string | undefined;
    overwrite: boolean;
    features?: {
      featureId: number | string;
      status: string;
      notes?: string;
    }[];
  },
) => {
  return api.post<any, EnvResponse>(`/environments/${envId}/batch`, body);
};
const updateTaskModuleStatus = (
  envId: number | string,
  body: {
    featureId: number;
    module: {
      id: number;
      name: string;
      state: boolean | null;
    };
  },
) => {
  return api.patch<any, EnvResponse>(
    `/environments/${envId}/features/module`,
    body,
  );
};
const updateTaskStatus = (
  envId: number | string,
  body: {
    featureId: number;
    status: string;
  },
) => {
  return api.patch<any, EnvResponse>(
    `/environments/${envId}/features/status`,
    body,
  );
};
const createSnapshot = (envId: number | string) => {
  return api.post<any, EnvResponse>(`environments/${envId}/snapshots`);
};
const getSnapshotsList = (envId: number | string, params: any) => {
  return api.get<any, EnvResponse>(`/environments/${envId}/snapshots`, {
    params,
  });
};
const restoreEnv = (envId: number | string, body: any) => {
  return api.put<any, EnvResponse>(`/environments/${envId}/restore`, body);
};
const addNotesForTask = (
  envId: number | string,
  body: {
    featureId: string | number;
    notes: string | null;
  },
) => {
  return api.patch<any, EnvResponse>(
    `/environments/${envId}/features/notes`,
    body,
  );
};

// for develop only, remove from final view
const createEnvsForProject = () => {
  return api.post<any, EnvResponse>('/environments', {
    projectId: 1,
    name: 'Prod',
    description: 'Prod env',
    isProduction: true,
  });
};
const addModulesProject = () => {
  return api.post<any, EnvResponse>('/projects/1/modules', {
    name: 'Web utils',
  });
};

export const envApi = {
  addNotesForTask,
  assigneeReleaseToEnv,
  assigneeTaskToEnv,
  assigneeReleaseAndTasks,
  getEnvsForProject,
  getEnvsTasks,
  getEnvDetails,
  updateTaskModuleStatus,
  updateTaskStatus,
  updateEnvDescription,
  clearEnv,
  createSnapshot,
  getSnapshotsList,
  restoreEnv,
  removeTasksFromEnv,
  unAssigneeReleaseToEnv,
  createEnvsForProject,
  addModulesProject,
};
