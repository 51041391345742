import React from 'react';
import { colors } from '../../../constants/colors';
import { IIcon } from '../../../types';

const EnvDev = ({ color = colors.icon_gray }: IIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M12.3807 13.3333C13.222 13.3333 13.9047 12.6513 13.9047 11.8093V8.76196L14.6667 7.99996L13.9047 7.23796V4.19063C13.9047 3.34863 13.2227 2.66663 12.3807 2.66663M3.6195 2.66663C2.7775 2.66663 2.0955 3.34863 2.0955 4.19063V7.23796L1.3335 7.99996L2.0955 8.76196V11.8093C2.0955 12.6513 2.7775 13.3333 3.6195 13.3333M6.00016 11.3333L10.0002 4.66663"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EnvDev;
