const Redmine = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 15.0317H3.89691L4.17526 11.6913L0.55647 10.8564L0 15.0317Z"
        fill="#4C4C4C"
      />
      <path
        d="M0.695312 10.2995L4.17469 11.1345L5.00975 8.21185L2.08707 6.68115L0.695312 10.2995Z"
        fill="#4C4C4C"
      />
      <path
        d="M2.36523 6.12428L5.14874 7.65521L7.37578 6.12428L5.28815 3.7583L2.36523 6.12428Z"
        fill="#4C4C4C"
      />
      <path
        d="M17.9526 15.0317H14.0559L13.7773 11.6913L17.3961 10.8564L17.9526 15.0317Z"
        fill="#4C4C4C"
      />
      <path
        d="M17.2576 10.2995L13.7782 11.1345L12.9434 8.21185L15.8658 6.68115L17.2576 10.2995Z"
        fill="#4C4C4C"
      />
      <path
        d="M15.5882 6.12428L12.8047 7.65521L10.5781 6.12428L12.6658 3.7583L15.5882 6.12428Z"
        fill="#4C4C4C"
      />
      <path
        d="M5.98438 3.34088L8.072 5.84604H10.0202L11.8297 3.34088L10.0202 2.78418H8.02306L5.98438 3.34088Z"
        fill="#4C4C4C"
      />
      <path
        d="M0 15.0317H3.89691L4.17526 11.6913L0.55647 10.8564L0 15.0317Z"
        fill="#9C0000"
      />
      <path
        d="M0.695312 10.2995L4.17469 11.1345L5.00975 8.21185L2.08707 6.68115L0.695312 10.2995Z"
        fill="#B50808"
      />
      <path
        d="M2.36523 6.12428L5.14874 7.65521L7.37578 6.12428L5.28815 3.7583L2.36523 6.12428Z"
        fill="#C61818"
      />
      <path
        d="M17.9526 15.0317H14.0559L13.7773 11.6913L17.3961 10.8564L17.9526 15.0317Z"
        fill="#9C0000"
      />
      <path
        d="M17.2576 10.2995L13.7782 11.1345L12.9434 8.21185L15.8658 6.68115L17.2576 10.2995Z"
        fill="#B50808"
      />
      <path
        d="M15.5882 6.12428L12.8047 7.65521L10.5781 6.12428L12.6658 3.7583L15.5882 6.12428Z"
        fill="#C61818"
      />
      <path
        d="M5.98438 3.34088L8.072 5.84604H10.0202L11.8297 3.34088L10.0202 2.78418H8.02306L5.98438 3.34088Z"
        fill="#CE3129"
      />
    </svg>
  );
};

export default Redmine;
