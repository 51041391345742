import React from 'react';
import { colors } from '../../../constants/colors';
import { IIcon } from '../../../types';

const EnvProd = ({ color = colors.icon_gray }: IIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14.6666 6H1.33325M9.33325 11.6667L10.9999 10L9.33325 8.33333M6.66658 8.33333L4.99992 10L6.66658 11.6667M1.33325 5.2V10.8C1.33325 11.9201 1.33325 12.4801 1.55124 12.908C1.74299 13.2843 2.04895 13.5903 2.42527 13.782C2.85309 14 3.41315 14 4.53325 14H11.4666C12.5867 14 13.1467 14 13.5746 13.782C13.9509 13.5903 14.2569 13.2843 14.4486 12.908C14.6666 12.4801 14.6666 11.9201 14.6666 10.8V5.2C14.6666 4.07989 14.6666 3.51985 14.4486 3.09202C14.2569 2.71569 13.9509 2.40973 13.5746 2.21799C13.1467 2 12.5867 2 11.4666 2H4.53325C3.41315 2 2.85309 2 2.42527 2.21799C2.04895 2.40973 1.74299 2.71569 1.55124 3.09202C1.33325 3.51984 1.33325 4.07989 1.33325 5.2Z"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EnvProd;
