import { lazy, memo, Suspense, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import classNames from 'classnames';

import { IEnvContainer, IType } from './types';
import ExpandIcon from '../../../assets/icons/expand-icon';
import { envApi } from '../../../api/env';
import Menu from '../../../components/shared/menu/menu';
import { HTTP_STATUSES, TOAST_MESSAGES } from '../../../constants';
import Loading from '../../../components/shared/loading/main-loading';
import { colors } from '../../../constants/colors';
import { EnvActionsButtons } from '../../../components/shared';
import { TASK_STATUS } from '../constants';
import ModalsManager from '../modals/modals-manager';
import MoveTaskSelect from '../components/move-selected-tasks/move-task-select';

import styles from './EnvContainer.module.css';

const ActiveListTable = lazy(() => import('./active-list'));
const WaitingListTable = lazy(() => import('./waiting-list'));

const emptyArray: any[] = [];

const EnvContainer = ({
  mode = 'dev',
  handleEnvExpanded,
  item,
  envs,
  updateData,
  moveTaskForm,
  moveReleaseForm,
  selectedTasks = [],
  setSelectedTasks,
  setUpdateData,
}: IEnvContainer) => {
  const isProduction = item?.isProduction;

  const [loadingTask, setLoadingTask] = useState(false);
  const [loadingWaitingTask, setLoadingWaitingTask] = useState(false);
  const [taskList, setTaskList] = useState<any>(emptyArray);
  const [waitingTaskList, setWaitingTaskList] = useState<any>(emptyArray);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [envDetails, setEnvDetails] = useState<any>({});
  const [isMultipleAction, setIsMultipleAction] = useState(false);
  const [isWaitingMultipleAction, setIsWaitingMultipleAction] = useState(false);
  const [multipleActionType, setMultipleActionType] =
    useState<IType>('no_type');
  const [isWaitingAction, setIsWaitingAction] = useState(false);
  const [modals, setModals] = useState({
    showAddTaskModal: false,
    showReleaseModal: false,
    showEditDescEnv: false,
    showRestore: false,
    showSuccessSnapshot: false,
    showCleanAll: false,
    showMoveRelease: false,
  });

  const headerClassName = classNames(styles.header, styles[`header_${mode}`]);
  const headerTitle = {
    dev: 'Dev',
    qa: 'QA',
    stage: 'Stage',
    prod: 'Production',
  };

  const getEnvDetails = async () => {
    if (!item?.id) return;
    const response = await envApi.getEnvDetails(item?.id);
    await getTaskList();
    await getTaskList(TASK_STATUS.WAITING);
    if (response?.status === HTTP_STATUSES.ok) {
      setEnvDetails(response.data);
    }
  };
  useEffect(() => {
    getEnvDetails();
  }, []);

  const getTaskList = useCallback(
    async (status = TASK_STATUS.RESOLVED_NEW) => {
      const isWaiting = status === TASK_STATUS.WAITING;
      isWaiting ? setLoadingWaitingTask(true) : setLoadingTask(true);
      if (envDetails?.id) {
        const params = {
          order: 'DESC',
          sort: isWaiting
            ? 'priority'
            : isProduction
              ? 'updatedAt'
              : 'featureId',
          filter: JSON.stringify({ status }),
        };
        const response = await envApi.getEnvsTasks(envDetails?.id, params);
        if (response.status === HTTP_STATUSES.ok) {
          if (isWaiting) {
            setWaitingTaskList(response?.data?.list);
          } else {
            setTaskList(response?.data?.list);
          }
        }
        isWaiting ? setLoadingWaitingTask(false) : setLoadingTask(false);
      }
    },
    [envDetails?.id, isProduction],
  );

  useEffect(() => {
    const fetchData = async () => {
      await getTaskList();
      await getTaskList(TASK_STATUS.WAITING);
    };
    fetchData();
  }, [getTaskList]);

  const handleSnapshot = useCallback(async () => {
    const response = await envApi.createSnapshot(envDetails?.id);
    if (response?.status === HTTP_STATUSES.created) {
      setModals((prev) => ({ ...prev, showSuccessSnapshot: true }));
    }
  }, [envDetails?.id]);
  const handleMultipleTasks = useCallback(
    (type: IType, isWaitingList: boolean) => {
      setMultipleActionType(type);
      moveTaskForm.setFieldValue('currentId', item?.id);
      moveTaskForm.setFieldValue('isWaitingAction', isWaitingList);

      if (isWaitingList) {
        setIsWaitingMultipleAction(true);
        setIsWaitingAction(true);
        setIsMultipleAction(false);
      } else {
        setIsMultipleAction(true);
        setIsWaitingAction(false);
        setIsWaitingMultipleAction(false);
      }
    },
    [],
  );
  const deleteSelectedTasks = useCallback(async () => {
    setDeleteLoading(true);
    const body = {
      featureIds: selectedTasks,
    };
    const response = await envApi.removeTasksFromEnv(envDetails?.id, body);
    if (response?.status === HTTP_STATUSES.created) {
      toast.success(TOAST_MESSAGES.DELETED);
      if (isProduction) {
        getTaskList(TASK_STATUS.WAITING);
        return;
      }
      getTaskList(
        isWaitingAction ? TASK_STATUS.WAITING : TASK_STATUS.RESOLVED_NEW,
      );
      setSelectedTasks([]);
    } else {
      toast.error(TOAST_MESSAGES.COMMON_ERROR);
    }
    setDeleteLoading(false);
  }, [selectedTasks]);
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, option: any) => {
      const shouldAdd = e.target.checked;
      setSelectedTasks((prevArray: any) =>
        shouldAdd
          ? [...prevArray, option]
          : prevArray.filter((el: any) => el?.id !== option?.id),
      );
    },
    [],
  );
  const handleAllChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    listType: 'waiting' | 'active',
  ) => {
    const currentTaskList = listType === 'waiting' ? waitingTaskList : taskList;
    if (e?.target?.checked) {
      const newArray = currentTaskList?.map((el: any) => ({
        id: el?.featureId,
        modules: el?.modules,
      }));
      return setSelectedTasks([...newArray]);
    }
    return setSelectedTasks([]);
  };
  const cancelMultipleAction = (type?: 'active' | 'waiting') => {
    setSelectedTasks([]);
    moveTaskForm.resetForm();
    if (!type) {
      setIsWaitingMultipleAction(false);
      setIsMultipleAction(false);
      return;
    }
    type === 'waiting'
      ? setIsWaitingMultipleAction(false)
      : setIsMultipleAction(false);
  };
  useEffect(() => {
    if (
      moveTaskForm.values.currentId &&
      moveTaskForm.values.currentId !== item?.id
    ) {
      cancelMultipleAction();
    }
  }, [moveTaskForm.values.currentId]);

  const isDisabledByTask = taskList.length <= 0;
  const isDisabledByWaitingTask = waitingTaskList.length <= 0;
  const isDisabledByRelease = Boolean(!envDetails?.releaseId);

  const commonActions = [
    {
      name: 'Edit deployment status',
      action: () => setModals((prev) => ({ ...prev, showEditDescEnv: true })),
      addUnderLine: true,
      isWaitingList: false,
    },
    {
      name: 'Create snapshot',
      action: handleSnapshot,
      addUnderLine: false,
      isWaitingList: false,
    },
  ];

  const getActions = (type: 'active' | 'waiting') => [
    {
      name: 'Assign release',
      action: () => setModals((prev) => ({ ...prev, showReleaseModal: true })),
      addUnderLine: false,
      isWaitingList: false,
    },
    {
      name: 'Add task',
      action: (isWaiting: boolean) => {
        setModals((prev) => ({ ...prev, showAddTaskModal: true }));
        setIsWaitingAction(isWaiting);
      },
      addUnderLine: false,
      isWaitingList: true,
    },
    {
      name: 'Move selected tasks',
      action: handleMultipleTasks,
      addUnderLine: false,
      isWaitingList: true,
      type: 'select',
      isDisabled:
        type === 'active' ? isDisabledByTask : isDisabledByWaitingTask,
    },
    {
      name: 'Move release tasks',
      action: () => setModals((prev) => ({ ...prev, showMoveRelease: true })),
      addUnderLine: false,
      isWaitingList: false,
      isDisabled: isDisabledByRelease,
    },
    ...commonActions,
    {
      name: 'Restore',
      action: () => setModals((prev) => ({ ...prev, showRestore: true })),
      addUnderLine: true,
      isWaitingList: false,
    },
    {
      name: 'Delete tasks',
      action: handleMultipleTasks,
      addUnderLine: false,
      isWaitingList: true,
      type: 'delete',
      isDisabled:
        type === 'active' ? isDisabledByTask : isDisabledByWaitingTask,
    },
    {
      name: 'Clean all',
      action: () => setModals((prev) => ({ ...prev, showCleanAll: true })),
      addUnderLine: false,
      isWaitingList: false,
    },
  ];
  const prodActions = [
    {
      name: 'Add task',
      action: () => {
        setModals((prev) => ({ ...prev, showAddTaskModal: true }));
        setIsWaitingAction(true);
      },
      addUnderLine: false,
    },
    {
      name: 'Move tasks',
      action: handleMultipleTasks,
      addUnderLine: false,
      type: 'select',
      isDisabled: isDisabledByWaitingTask,
    },
    ...commonActions,
    {
      name: 'Restore',
      action: () => setModals((prev) => ({ ...prev, showRestore: true })),
      addUnderLine: true,
    },
    {
      name: 'Delete tasks',
      action: handleMultipleTasks,
      addUnderLine: false,
      type: 'delete',
      isDisabled: isDisabledByWaitingTask,
    },
  ];
  const isDeleteTasks = multipleActionType === 'delete';

  useEffect(() => {
    if (updateData) {
      const isCurrentEnv =
        moveTaskForm?.values?.targetEnvDetails?.envId == item?.id ||
        moveTaskForm?.values?.currentId == item?.id ||
        moveReleaseForm?.values?.targetEnv == item?.id;
      if (isCurrentEnv) {
        const fetchData = async () => {
          if (moveReleaseForm?.values?.targetEnv) {
            await getTaskList();
            const response = await envApi.getEnvDetails(
              moveReleaseForm?.values?.targetEnv,
            );
            if (response?.status === HTTP_STATUSES.ok) {
              setEnvDetails(response.data);
            }
            return;
          }
          await getTaskList();
          await getTaskList(TASK_STATUS.WAITING);
        };
        fetchData();
        setUpdateData(false);
      }
    }
  }, [
    updateData,
    item?.id,
    moveTaskForm?.values?.currentId,
    moveTaskForm?.values?.targetEnvDetails?.envId,
    moveReleaseForm?.values?.targetEnv,
  ]);

  const disableAction = isDeleteTasks
    ? deleteLoading || !selectedTasks?.length
    : moveTaskForm.isSubmitting ||
      !moveTaskForm?.values?.targetEnvDetails?.envId ||
      !selectedTasks?.length;

  const envAction = () =>
    isDeleteTasks ? deleteSelectedTasks() : moveTaskForm.submitForm();

  return (
    <div className={styles.wrapper}>
      <div className={headerClassName}>
        <div className={styles.coverHeader}>
          <div className={styles.envDetails}>
            <span>{headerTitle[mode]}</span>
            <span className={styles.release}>
              {envDetails?.release?.releaseNumber
                ? `Release ${envDetails?.release?.releaseNumber}`
                : ''}
            </span>
          </div>
          {isMultipleAction ? (
            <>
              <EnvActionsButtons
                onCancel={() => cancelMultipleAction('active')}
                onAction={envAction}
                type={multipleActionType}
                isSpecialColorCancelBtn
                disabled={disableAction}
              />
            </>
          ) : (
            <div className={styles.envActions}>
              <ExpandIcon
                className={styles.btnIcon}
                onClick={() => handleEnvExpanded(mode)}
              />
              <Menu
                color={colors.white}
                actions={isProduction ? prodActions : getActions('active')}
                isHoverChanges
              />
            </div>
          )}
        </div>
      </div>

      <div className={styles.tasksWrapper}>
        {isMultipleAction && !isDeleteTasks ? (
          <MoveTaskSelect
            type="column-reverse"
            envs={envs}
            currentEnv={envDetails?.id}
            currentList="active"
            form={moveTaskForm}
            isProduction={isProduction}
          />
        ) : (
          <div className={styles.envDescription}>{envDetails?.description}</div>
        )}

        {isProduction ? (
          <>
            <div className={styles.title} style={{ paddingTop: '0' }}>
              <span>Waiting list</span>
            </div>
            <Suspense fallback={<Loading />}>
              <WaitingListTable
                taskList={waitingTaskList}
                isMultipleAction={isMultipleAction}
                handleChange={handleChange}
                isLoading={loadingWaitingTask}
                handleAllChange={handleAllChange}
                selectedTasks={selectedTasks}
                isProdEnv={isProduction}
              />
            </Suspense>
            <div className={styles.title}>
              <span>Production history</span>
            </div>
            <Suspense fallback={<Loading />}>
              <ActiveListTable taskList={taskList} isLoading={loadingTask} />
            </Suspense>
          </>
        ) : (
          <>
            <Suspense fallback={<Loading />}>
              <ActiveListTable
                taskList={taskList}
                isMultipleAction={isMultipleAction}
                handleChange={handleChange}
                isLoading={loadingTask}
                handleAllChange={handleAllChange}
                selectedTasks={selectedTasks}
              />
            </Suspense>

            <div className={styles.title}>
              <span>Waiting list</span>
              {isWaitingMultipleAction ? (
                <>
                  <EnvActionsButtons
                    onCancel={() => cancelMultipleAction('waiting')}
                    onAction={envAction}
                    type={multipleActionType}
                    disabled={disableAction}
                  />
                </>
              ) : (
                <Menu
                  actions={getActions('waiting').filter(
                    (el) => el.isWaitingList,
                  )}
                  isWaitingOnly
                />
              )}
            </div>
            {isWaitingMultipleAction && !isDeleteTasks ? (
              <MoveTaskSelect
                type="column-reverse"
                envs={envs}
                currentEnv={envDetails?.id}
                currentList="waiting"
                form={moveTaskForm}
                isProduction={isProduction}
              />
            ) : null}
            <Suspense fallback={<Loading />}>
              <WaitingListTable
                taskList={waitingTaskList}
                isMultipleAction={isWaitingMultipleAction}
                handleChange={handleChange}
                isLoading={loadingWaitingTask}
                handleAllChange={handleAllChange}
                selectedTasks={selectedTasks}
              />
            </Suspense>
          </>
        )}
      </div>
      <ModalsManager
        isWaitingAction={isWaitingAction}
        envDetails={envDetails}
        getTaskList={getTaskList}
        getEnvDetails={getEnvDetails}
        setModals={setModals}
        modals={modals}
        envs={envs}
        isProdEnv={isProduction}
        setUpdateData={setUpdateData}
        moveReleaseForm={moveReleaseForm}
      />
    </div>
  );
};

export default memo(EnvContainer);
