import { MainHeader } from '../../shared';
import PageWrapper from '../PageWrapper/page-wrapper';
import { ISeo } from './types';
// import styles from './Seo.module.scss';

const Seo = ({ children, withNavigation = true }: ISeo) => {
  return (
    <main className="wrapper">
      {withNavigation ? (
        <>
          <MainHeader />
          <PageWrapper>{children}</PageWrapper>
        </>
      ) : (
        <PageWrapper>{children}</PageWrapper>
      )}
    </main>
  );
};

export default Seo;
