import React from 'react';
import { colors } from '../../../constants/colors';
import { IIcon } from '../../../types';

const EnvStage = ({ color = colors.icon_gray }: IIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M6.49992 11.3334L3.16659 14.6667M10.4999 11.3334L13.8333 14.6667M8.49992 1.33337V2.66671M8.49992 14.6667V11.3334M3.96659 11.3334H13.0333C13.78 11.3334 14.1534 11.3334 14.4386 11.188C14.6895 11.0602 14.8935 10.8562 15.0213 10.6054C15.1666 10.3202 15.1666 9.94677 15.1666 9.20004V4.80004C15.1666 4.0533 15.1666 3.67993 15.0213 3.39472C14.8935 3.14383 14.6895 2.93986 14.4386 2.81203C14.1534 2.66671 13.78 2.66671 13.0333 2.66671H3.96659C3.21985 2.66671 2.84648 2.66671 2.56127 2.81203C2.31038 2.93986 2.10641 3.14383 1.97858 3.39472C1.83325 3.67993 1.83325 4.0533 1.83325 4.80004V9.20004C1.83325 9.94677 1.83325 10.3202 1.97858 10.6054C2.10641 10.8562 2.31038 11.0602 2.56127 11.188C2.84648 11.3334 3.21985 11.3334 3.96659 11.3334Z"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EnvStage;
