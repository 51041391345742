import React, { useState } from 'react';
import { IRadioButton } from './types';
import CheckMark from '../../../assets/icons/check-mark';
import CheckMarkEmpty from '../../../assets/icons/check-mark-empty';
import styles from './RadioButton.module.css';

const RadioButton: React.FC<IRadioButton> = ({
  options,
  defaultValue,
  onChange,
  customClassName = styles.radioButton,
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  const handleClick = (value: string) => {
    setSelectedValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div
      className={styles.radioButtonGroup}
      style={{ gridTemplateColumns: `repeat(${options?.length}, 1fr)` }}
    >
      {options.map((option) => (
        <button
          key={option.value}
          className={`${customClassName} ${selectedValue === option.value ? styles.active : ''}`}
          onClick={() => handleClick(option.value)}
        >
          <span className={styles.leftSide}>
            {selectedValue === option.value ? (
              <CheckMark />
            ) : (
              <CheckMarkEmpty />
            )}
            {option.label}
          </span>

          <span style={{ fontWeight: '400' }}>{option.additionalInfo}</span>
        </button>
      ))}
    </div>
  );
};

export default RadioButton;
