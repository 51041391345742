import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { object, string } from 'yup';

import { ApproveModal, Checkbox, Select } from '../../../components/shared';
import { envApi } from '../../../api/env';
import { taskApi } from '../../../api/task';
import { HTTP_STATUSES, TOAST_MESSAGES } from '../../../constants';
import Loading from '../../../components/shared/loading/main-loading';
import ReleaseConflict from './release-conflict';
import { IModalActions } from './types';
import styles from '../expired-view/Styles.module.css';

const formValidation = object().shape({
  envId: string().required('Please select environment'),
});

const MoveRelease = ({
  close,
  name = '',
  setUpdateData,
  release = '',
  releaseId = '',
  moveReleaseForm,
}: IModalActions) => {
  const [envsOption, setEnvsOption] = useState<any>([]);
  const [releasesTasks, setReleasesTasks] = useState<any>([]);
  const [tasksLoading, setTasksLoading] = useState(false);
  const [isReleaseConflict, setIsReleaseConflict] = useState(false);

  const form = useFormik({
    initialValues: {
      envId: '',
      overwrite: false,
      featureIds: [],
    },
    onSubmit: async (values) => {
      const body = {
        releaseId,
        overwrite: values?.overwrite,
      };
      const response = await envApi.assigneeReleaseAndTasks(
        values?.envId,
        body,
      );
      if (response?.status === HTTP_STATUSES.created) {
        close();
        toast.success(TOAST_MESSAGES.SAVED);
        setUpdateData && setUpdateData(true);
      } else if (response?.status === HTTP_STATUSES.conflict) {
        setIsReleaseConflict(true);
      } else {
        toast.error(TOAST_MESSAGES.COMMON_ERROR);
      }
    },
    validateOnChange: false,
    validationSchema: formValidation,
  });

  const getEnvsData = async () => {
    const resEnv = await envApi.getEnvsForProject(1);
    if (resEnv?.status === HTTP_STATUSES.ok) {
      const envs = resEnv?.data?.environments
        ?.filter((el: any) => el?.name !== name)
        ?.filter((el: any) => !el?.isProduction)
        .map((el: any) => {
          return {
            label: el?.name,
            value: el?.id,
          };
        });
      setEnvsOption(envs);
    }
  };
  const getReleaseTasks = async () => {
    setReleasesTasks([]);
    setTasksLoading(true);
    const params: any = {
      skip: 0,
      take: 100,
      sort: 'id',
      order: 'ASC',
      filter: JSON.stringify({ releaseId }),
    };
    const response = await taskApi.getTasksList(params);
    if (response?.status === HTTP_STATUSES.ok) {
      setReleasesTasks(response?.data?.list);
      form.setFieldValue(
        'featureIds',
        response?.data?.list?.map((el: any) => el?.id),
      );
    }
    setTasksLoading(false);
  };

  useEffect(() => {
    getEnvsData();
  }, []);
  useEffect(() => {
    if (releaseId) {
      getReleaseTasks();
    }
  }, [releaseId]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue('overwrite', e.target.checked);
  };

  return (
    <>
      {isReleaseConflict ? (
        <ReleaseConflict
          callback={() => {
            form.setFieldValue('overwrite', true);
            form.submitForm();
          }}
          close={close}
        />
      ) : (
        <ApproveModal
          btnText="Apply"
          callback={form.submitForm}
          closeModal={close}
          disabled={form.isSubmitting || !form.values.envId}
        >
          <div className={styles.editWrapper}>
            <span className={styles.modal__title}>Move release tasks</span>
            <div className={styles.description}>
              Select the target environment to which the Release {release} tasks
              will be moved from the {name} environment.
            </div>
            <div style={{ marginTop: '16px' }} />
            <Select
              label="Move to"
              options={envsOption}
              onChange={(v) => {
                form.setFieldValue('envId', v.value);
                moveReleaseForm &&
                  moveReleaseForm.setFieldValue('targetEnv', v.value);
              }}
              name="envId"
              value={form.values.envId}
            />
            <div style={{ marginTop: '16px' }} />

            {tasksLoading ? (
              <div className={styles.loading}>
                <Loading />
              </div>
            ) : null}
            {releasesTasks?.length ? (
              <div className={styles.container}>
                <span className={styles.taskTitle}>Task list</span>
                <div className={styles.taskListContainer}>
                  {releasesTasks?.length
                    ? releasesTasks?.map((el: any) => (
                        <span key={el?.id} className={styles.taskItem}>
                          <span className="idTask">#{el?.id}:</span>
                          {el?.name}
                        </span>
                      ))
                    : null}
                </div>
              </div>
            ) : null}
            <div style={{ marginTop: '10px' }} />
            <Checkbox
              label="Overwrite the current task list in the target environment."
              onChange={handleChange}
              checked={form?.values?.overwrite}
            />
          </div>
        </ApproveModal>
      )}
    </>
  );
};

export default MoveRelease;
