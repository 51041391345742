import React, { useEffect, useState } from 'react';
import styles from './TripleSwitch.module.css';
import { colors } from '../../../constants/colors';
import TripleFalseIcon from '../../../assets/icons/tripleFalse';
import TripleOffIcon from '../../../assets/icons/tripleOff';
import TripleTrueIcon from '../../../assets/icons/tripleTrue';

export const labels = [
  {
    name: 'False',
    color: colors.danger,
    value: false,
  },
  {
    name: 'Off',
    color: colors.disabled_font,
    value: null,
  },
  {
    name: 'True',
    color: colors.main_font,
    value: true,
  },
];
export type SwitchState = 'false' | 'off' | 'true';

interface TripleSwitchProps {
  initialState?: string;
  onChange?: (state: any) => void;
}

const TripleSwitch: React.FC<TripleSwitchProps> = ({
  initialState = 'off',
  onChange,
}) => {
  const [state, setState] = useState<string>(initialState);
  const [currentColor, setCurrentColor] = useState<string>();

  const handleClick = () => {
    const nextState =
      state === 'false' ? 'off' : state === 'off' ? 'true' : 'false';

    setState(nextState);
    const currentOption = labels.find(
      (label: any) => label.name.toLowerCase() === nextState,
    );
    setCurrentColor(currentOption?.color);
    if (onChange) onChange(currentOption);
  };

  useEffect(() => {
    const currentOption = labels.find(
      (label: any) => label.name.toLowerCase() === state,
    );
    setCurrentColor(currentOption?.color);
  }, [initialState]);

  return (
    <div className={styles.trioSwitchContainer}>
      <div
        className={`${styles.switch} ${styles[state]}`}
        onClick={handleClick}
      >
        <div className={styles.switchIcon}>
          {state === 'false' && (
            <TripleFalseIcon className={styles.falseIcon} />
          )}
          {state === 'off' && <TripleOffIcon className={styles.offIcon} />}
          {state === 'true' && <TripleTrueIcon className={styles.trueIcon} />}
        </div>
      </div>
      <span
        className={`${styles.state} ${styles[`state${state}`]}`}
        style={{ color: currentColor }}
      >
        {state}
      </span>
    </div>
  );
};

export default TripleSwitch;
