import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useFormik } from 'formik';
import { object, mixed } from 'yup';

import EnvContainer from '../../screens/EnvDashboard/main-view/env-container';
import { envApi } from '../../api/env';
import Tabs from '../../components/shared/tabs/tabs';
import { taskNavigation } from './constants';
import { RoutesData } from '../../types/routes/routes';
import { HTTP_STATUSES, TOAST_MESSAGES } from '../../constants';
import { EnvDetails } from '../../screens/EnvDashboard';
import styles from './Dashboard.module.css';

const moveTasksValidation = object().shape({
  targetEnv: mixed()
    .test(
      'is-number-or-string',
      'Set target env',
      (value) => typeof value === 'number' || typeof value === 'string',
    )
    .required('This filed is required'),
});

const emptyArray: any[] = [];
const order = ['Dev', 'QA', 'Stage', 'Prod'];

function EnvironmentsDashboard() {
  const location = useLocation();
  const navigate = useNavigate();

  const [expandedView, setExpandedView] = useState(false);
  const [selectedTasks, setSelectedTasks] = useState<any>(emptyArray);
  const [envs, setEnvs] = useState<any[]>([]);
  const [envsOptions, setEnvsOptions] = useState<any[]>(emptyArray);
  const [envTabs, setEnvTabs] = useState<any[]>(emptyArray);
  const [updateData, setUpdateData] = useState(false);

  const getData = async () => {
    const resEnv = await envApi.getEnvsForProject(1);
    if (resEnv?.status === HTTP_STATUSES.ok) {
      setEnvs(resEnv?.data?.environments);
      setEnvsOptions(
        resEnv?.data?.environments.map((el: any) => {
          return {
            label: el?.name,
            value: el?.id,
            isProduction: el?.isProduction,
          };
        }),
      );
      setEnvTabs(
        resEnv?.data?.environments?.map((env: any) => {
          const path = env?.name?.toLowerCase();
          return {
            path: `${RoutesData.EnvironmentsDashboard}/${path}`,
            content: EnvDetails,
            name: env?.name,
            id: env?.id,
          };
        }),
      );
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const handleEnvExpanded = useCallback((tab: string) => {
    navigate(`${RoutesData.EnvironmentsDashboard}/${tab}`);
    setExpandedView(true);
  }, []);

  const componentTab =
    envTabs.find((el) => location.pathname.includes(el.path)) || envTabs[0];
  const Component = componentTab?.content;

  const moveTaskForm = useFormik({
    initialValues: {
      targetEnv: null,
      targetEnvDetails: { envId: '', filter: '' },
      overwrite: false,
      saveFeatureModuleStatuses: false,
      currentId: '',
      isWaitingAction: false,
    },
    onSubmit: async (values) => {
      if (values?.currentId === values?.targetEnvDetails?.envId) {
        const deleteResponse = await envApi.removeTasksFromEnv(
          values?.currentId,
          {
            featureIds: selectedTasks,
          },
        );
        if (deleteResponse?.status !== HTTP_STATUSES.created) return;
      }
      const tasksArray = selectedTasks?.map((el: any) => ({
        featureId: el?.id,
        status: values.targetEnvDetails.filter,
        modules: el?.modules,
      }));
      const envId = values?.targetEnvDetails?.envId;
      const body = {
        overwrite: values.overwrite,
        saveFeatureModuleStatuses: values.saveFeatureModuleStatuses,
        features: tasksArray,
      };
      const response = await envApi.assigneeTaskToEnv(envId, body);
      if (response?.status === HTTP_STATUSES.created) {
        setUpdateData(true);
        toast.success(TOAST_MESSAGES.SAVED);
        setSelectedTasks([]);
      } else if (response?.status === HTTP_STATUSES.conflict) {
        toast.error(TOAST_MESSAGES.TASK_CONFLICT);
      } else {
        toast.error(TOAST_MESSAGES.COMMON_ERROR);
      }
    },
    validationSchema: moveTasksValidation,
  });
  const moveReleaseForm = useFormik({
    initialValues: {
      targetEnv: null,
    },
    onSubmit: () => {},
  });

  return (
    <>
      <ToastContainer />
      {expandedView ? (
        <div className={styles.expandedWrapper}>
          <div className={styles.tabsWrapper}>
            <Tabs navigateArr={taskNavigation} tabClassName={styles.tabEnv} />
          </div>
          <div style={{ marginTop: '16px' }} />
          {Component && (
            <Component
              data={componentTab}
              setExpandedView={() => setExpandedView(false)}
              envs={envsOptions}
              moveTaskForm={moveTaskForm}
              selectedTasks={selectedTasks}
              setSelectedTasks={setSelectedTasks}
              updateData={updateData}
              setUpdateData={setUpdateData}
            />
          )}
        </div>
      ) : (
        <div className={styles.envsContainer}>
          {envs?.length ? (
            envs
              ?.sort(
                (a: any, b: any) =>
                  order.indexOf(a?.name) - order.indexOf(b?.name),
              )
              ?.map((el: any) => {
                return (
                  <EnvContainer
                    key={el?.id}
                    mode={el?.name?.toLowerCase()}
                    handleEnvExpanded={handleEnvExpanded}
                    item={el}
                    envs={envsOptions}
                    moveTaskForm={moveTaskForm}
                    moveReleaseForm={moveReleaseForm}
                    selectedTasks={selectedTasks}
                    setSelectedTasks={setSelectedTasks}
                    updateData={updateData}
                    setUpdateData={setUpdateData}
                  />
                );
              })
          ) : (
            <>no data</>
          )}
        </div>
      )}
    </>
  );
}

export default EnvironmentsDashboard;
