// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Menu_menuContainer__1iPaw {
  position: relative;
}
.Menu_wrapper__yi4E4 {
  position: absolute;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid var(--silver);
  background: var(--white);
  box-shadow: 0px 12px 24px 0px var(--box-shadow);
  padding: 4px;
  right: 0;
  width: 210px;
  z-index: 3;
}
.Menu_actionItem__KyES3 {
  color: var(--main-font);
  cursor: pointer;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 8px;
  width: 100%;
}
.Menu_disabledAction__31xQw {
  color: var(--disabled-font);
  pointer-events: none;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 8px;
  width: 100%;
}
.Menu_actionItem__KyES3:hover {
  border-radius: 8px;
  background: var(--light-blue);
}
.Menu_btnIcon__3qTle,
.Menu_btnIconHover__hOzhw {
  cursor: pointer;
}
.Menu_btnIconHover__hOzhw:hover > path {
  stroke: var(--main-blue);
}
`, "",{"version":3,"sources":["webpack://./src/renderer/components/shared/menu/Menu.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,uBAAuB;EACvB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,+BAA+B;EAC/B,wBAAwB;EACxB,+CAA+C;EAC/C,YAAY;EACZ,QAAQ;EACR,YAAY;EACZ,UAAU;AACZ;AACA;EACE,uBAAuB;EACvB,eAAe;EACf,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,YAAY;EACZ,WAAW;AACb;AACA;EACE,2BAA2B;EAC3B,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,YAAY;EACZ,WAAW;AACb;AACA;EACE,kBAAkB;EAClB,6BAA6B;AAC/B;AACA;;EAEE,eAAe;AACjB;AACA;EACE,wBAAwB;AAC1B","sourcesContent":[".menuContainer {\n  position: relative;\n}\n.wrapper {\n  position: absolute;\n  align-items: flex-start;\n  display: flex;\n  flex-direction: column;\n  border-radius: 8px;\n  border: 1px solid var(--silver);\n  background: var(--white);\n  box-shadow: 0px 12px 24px 0px var(--box-shadow);\n  padding: 4px;\n  right: 0;\n  width: 210px;\n  z-index: 3;\n}\n.actionItem {\n  color: var(--main-font);\n  cursor: pointer;\n  font-size: 15px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 20px;\n  padding: 8px;\n  width: 100%;\n}\n.disabledAction {\n  color: var(--disabled-font);\n  pointer-events: none;\n  font-size: 15px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 20px;\n  padding: 8px;\n  width: 100%;\n}\n.actionItem:hover {\n  border-radius: 8px;\n  background: var(--light-blue);\n}\n.btnIcon,\n.btnIconHover {\n  cursor: pointer;\n}\n.btnIconHover:hover > path {\n  stroke: var(--main-blue);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuContainer": `Menu_menuContainer__1iPaw`,
	"wrapper": `Menu_wrapper__yi4E4`,
	"actionItem": `Menu_actionItem__KyES3`,
	"disabledAction": `Menu_disabledAction__31xQw`,
	"btnIcon": `Menu_btnIcon__3qTle`,
	"btnIconHover": `Menu_btnIconHover__hOzhw`
};
export default ___CSS_LOADER_EXPORT___;
