// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switchWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.detailsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  gap: 4px;
}
.switch {
  width: 40px;
  height: 24px;
  border-radius: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.switch.inactive {
  background-color: var(--light-background);
}
.switch.active {
  background-color: var(--main-font);
}
.switch-icon {
  background-color: var(--white);
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s;
}
.switch.active .switch-icon {
  transform: translateX(16px);
}
.switch.inactive .switch-icon {
  transform: translateX(0);
}
.falseIcon > path {
  stroke: var(--disabled-font);
}
.trueIcon > path {
  stroke: var(--main-font);
}
.detailsContainer:hover .falseIcon > path,
.detailsContainer:hover .trueIcon > path,
.detailsContainer:hover .switch.active {
  stroke: var(--main-blue);
  background-color: var(--main-blue);
}
`, "",{"version":3,"sources":["webpack://./src/renderer/components/shared/switch/Switch.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;EACf,QAAQ;AACV;AACA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,YAAY;EACZ,eAAe;EACf,iCAAiC;AACnC;AACA;EACE,yCAAyC;AAC3C;AACA;EACE,kCAAkC;AACpC;AACA;EACE,8BAA8B;EAC9B,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,0BAA0B;AAC5B;AACA;EACE,2BAA2B;AAC7B;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,4BAA4B;AAC9B;AACA;EACE,wBAAwB;AAC1B;AACA;;;EAGE,wBAAwB;EACxB,kCAAkC;AACpC","sourcesContent":[".switchWrapper {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  gap: 6px;\n}\n.detailsContainer {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  cursor: pointer;\n  gap: 4px;\n}\n.switch {\n  width: 40px;\n  height: 24px;\n  border-radius: 50px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 4px;\n  cursor: pointer;\n  transition: background-color 0.3s;\n}\n.switch.inactive {\n  background-color: var(--light-background);\n}\n.switch.active {\n  background-color: var(--main-font);\n}\n.switch-icon {\n  background-color: var(--white);\n  border-radius: 50%;\n  width: 16px;\n  height: 16px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  transition: transform 0.3s;\n}\n.switch.active .switch-icon {\n  transform: translateX(16px);\n}\n.switch.inactive .switch-icon {\n  transform: translateX(0);\n}\n.falseIcon > path {\n  stroke: var(--disabled-font);\n}\n.trueIcon > path {\n  stroke: var(--main-font);\n}\n.detailsContainer:hover .falseIcon > path,\n.detailsContainer:hover .trueIcon > path,\n.detailsContainer:hover .switch.active {\n  stroke: var(--main-blue);\n  background-color: var(--main-blue);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
