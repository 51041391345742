export const TOAST_MESSAGES = {
  ASSIGNEE_RELEASE_ERROR: 'Release already assigned',
  CANT_BE_UPDATED: 'Cant save updates',
  CANT_ADD_ASSIGNEES: 'Cant save assigned users.',
  COMMON_ERROR: 'Something went wrong',
  COPIED: 'Copied',
  CREATED: 'Successfully created',
  DELETED: 'Successfully deleted',
  MODULE_CHANGED: 'Module status successfully changed',
  LOADING: 'Loading...',
  PENDING: 'Pending',
  SAVED: 'Successfully saved',
  SENT: 'Successfully sent',
  STATUS_CHANGED: 'Task status successfully changed',
  TASK_NOTES: 'Task notes successfully updated',
  TASK_CONFLICT: 'Current task is already on env. Please use move task.',
  UPDATED: 'Updated successfully',
  UNASSIGNED: 'Unassigned successfully',
  RESTORED: 'Successfully restored',
  CLEANED: 'All tasks were successfully deleted',
  PRIO_LIMIT: 'Please provide priority value from 0 to 100',
};
