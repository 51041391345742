import api from './axios.config';

type UserResponse = any;

// eslint-disable-next-line import/prefer-default-export
export const getAuthUserRequest = () => api.get<any, UserResponse>('/users/me');
const getUsers = () => api.get<any, UserResponse>('/users');

export const usersApi = {
  getUsers,
};
