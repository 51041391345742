import api from './axios.config';

type ReleaseResponse = any;
const defaultParams: any = {
  skip: 0,
  take: 10,
  sort: 'createdAt',
  order: 'ASC',
};

const defaultReleaseBody: any = {
  projectId: 1,
  releaseNumber: 8,
  type: 'MINI',
  status: 'NOT_STARTED',
  planedDate: new Date('2025-11-11'),
};

const getReleasesList = (params = defaultParams) => {
  return api.get<any, ReleaseResponse>('/releases', { params });
};
const getReleaseById = (id: string | number) => {
  return api.get<any, ReleaseResponse>(`/releases/${id}`);
};
const deleteRelease = (id: string | number) => {
  return api.delete<any, ReleaseResponse>(`/releases/${id}`);
};
const createRelease = (body = defaultReleaseBody) => {
  return api.post<any, ReleaseResponse>('/releases', body);
};
const updateRelease = (id: string | number, body: any) => {
  return api.patch<any, ReleaseResponse>(`/releases/${id}`, body);
};

export const releaseApi = {
  deleteRelease,
  createRelease,
  getReleasesList,
  getReleaseById,
  updateRelease,
};
