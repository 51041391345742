// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tooltip_tooltipStatus__rLH11 {
  color: var(--white);
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  border-radius: 4px;
  padding: 1px 4px;
}
`, "",{"version":3,"sources":["webpack://./src/renderer/components/shared/tooltip/Tooltip.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".tooltipStatus {\n  color: var(--white);\n  font-size: 10px;\n  font-style: normal;\n  font-weight: 500;\n  border-radius: 4px;\n  padding: 1px 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltipStatus": `Tooltip_tooltipStatus__rLH11`
};
export default ___CSS_LOADER_EXPORT___;
