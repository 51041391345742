import { memo, useRef, useState } from 'react';
import MenuIcon from '../../../assets/icons/menu';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { colors } from '../../../constants/colors';
import styles from './Menu.module.css';

function Menu({
  actions,
  color = colors.main_font,
  isHoverChanges = false,
  isWaitingOnly = false,
}: any) {
  const ref = useRef(null);
  const [showMenu, setShowMenu] = useState(false);

  const handleCloseOptions = () => setShowMenu(false);

  useOutsideClick(ref, handleCloseOptions);

  return (
    <div className={styles.menuContainer} ref={ref}>
      <MenuIcon
        className={isHoverChanges ? styles.btnIcon : styles.btnIconHover}
        onClick={() => setShowMenu(!showMenu)}
        color={color}
      />
      {showMenu && (
        <div className={styles.wrapper}>
          {actions?.map((item: any) => {
            return (
              <div
                key={item?.name}
                className={
                  item?.isDisabled ? styles.disabledAction : styles.actionItem
                }
                onClick={() =>
                  item?.type
                    ? item?.action(item?.type, isWaitingOnly)
                    : item?.action(isWaitingOnly)
                }
                role="button"
                style={{
                  borderBottom: item?.addUnderLine
                    ? `1px solid ${colors.light_background}`
                    : '',
                }}
              >
                {item?.name}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default memo(Menu);
