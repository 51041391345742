import React from 'react';
import classNames from 'classnames';

import { ICheckbox } from './types';

import styles from './CheckBox.module.css';

const Checkbox: React.FC<ICheckbox> = ({ label, disabled, ...props }) => {
  const labelClassNames = classNames(styles.checkboxLabel, {
    [styles.checkboxLabelDisabled]: disabled,
  });
  const containerClassNames = classNames(styles.checkboxContainer, {
    [styles.checkboxContainerDisabled]: disabled,
  });
  return (
    <label className={containerClassNames}>
      <input
        type="checkbox"
        className={styles.checkboxInput}
        disabled={disabled}
        {...props}
      />
      {label ? <span className={labelClassNames}>{label}</span> : null}
    </label>
  );
};

export default Checkbox;
