import React from 'react';
import ModalWrapper from '../modal-wrapper';
import OutlineBtn from '../../buttons/OutlinedBtn/OutlineBtn';
import PrimaryBtn from '../../buttons/PrimaryBtn/PrimaryBtn';
import { IApproveModal } from '../types';

const ApproveModal: React.FC<IApproveModal> = ({
  title,
  callback = () => {},
  closeModal = () => {},
  text,
  btnText = 'Approve',
  disabled = false,
  children,
}) => {
  return (
    <ModalWrapper>
      <div className="modal">
        {children ? (
          children
        ) : (
          <>
            <div className="modal__title">{title}</div>
            {typeof text === 'string' ? (
              <div
                className="modal__body"
                dangerouslySetInnerHTML={{ __html: text }}
              />
            ) : (
              <div className="modal__body">{text}</div>
            )}
          </>
        )}
        <div className="modal__options">
          <PrimaryBtn onClick={callback} text={btnText} disabled={disabled} />
          <OutlineBtn onClick={closeModal} text="Cancel" isSecondary />
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ApproveModal;
