import { EnvironmentsDashboard, Tasks } from '../containers';
import { RoutesData, RoutesProps } from '../types/routes/routes';

const routes: RoutesProps[] = [
  {
    component: <Tasks />,
    path: RoutesData.Tasks,
    withNavigation: true,
    exact: false,
  },
  {
    component: <Tasks />,
    path: RoutesData.Planning,
    withNavigation: true,
    exact: true,
  },
  {
    component: <Tasks />,
    path: RoutesData.Estimate,
    withNavigation: true,
    exact: true,
  },
  {
    component: <Tasks />,
    path: RoutesData.SDLC,
    withNavigation: true,
    exact: true,
  },
  {
    component: <Tasks />,
    path: RoutesData.CreateTask,
    withNavigation: true,
    exact: true,
  },
  {
    component: <EnvironmentsDashboard />,
    path: RoutesData.EnvironmentsDashboard,
    withNavigation: true,
    exact: true,
  },
  {
    component: <EnvironmentsDashboard />,
    path: RoutesData.DEV,
    withNavigation: true,
    exact: true,
  },
  {
    component: <EnvironmentsDashboard />,
    path: RoutesData.QA,
    withNavigation: true,
    exact: true,
  },
  {
    component: <EnvironmentsDashboard />,
    path: RoutesData.Stage,
    withNavigation: true,
    exact: true,
  },
  {
    component: <EnvironmentsDashboard />,
    path: RoutesData.Prod,
    withNavigation: true,
    exact: true,
  },
  {
    component: <div>Notes</div>,
    path: RoutesData.Notes,
    withNavigation: true,
    exact: true,
  },
  {
    component: <div>Releases</div>,
    path: RoutesData.Releases,
    withNavigation: true,
    exact: true,
  },
];

export default routes;
