// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ToggleButtonGroup_toggleButtonGroup__14toY {
  display: grid;
  gap: 4px;
  width: 100%;
}
.ToggleButtonGroup_toggleButton__wPGyG {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border: 1px solid var(--silver);
  border-radius: 8px;
  background-color: var(--white);
  color: var(--main-font);
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.ToggleButtonGroup_toggleButton__wPGyG.ToggleButtonGroup_active__HeK1O {
  border: 1px solid var(--main-font);
  color: var(--main-font);
}

.ToggleButtonGroup_checkmark__dfedU {
  margin-left: 8px;
}

.ToggleButtonGroup_toggleButton__wPGyG:hover {
  background-color: #f5f5f5;
}

.ToggleButtonGroup_toggleButton__wPGyG:focus {
  outline: none;
}
.ToggleButtonGroup_emptyCheckmark__IKix0 {
  border-radius: 8px;
  border: 1px solid var(--main-font);
  height: 16px;
  width: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/renderer/components/shared/toggle-button/ToggleButtonGroup.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,QAAQ;EACR,WAAW;AACb;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,kBAAkB;EAClB,+BAA+B;EAC/B,kBAAkB;EAClB,8BAA8B;EAC9B,uBAAuB;EACvB,eAAe;EACf,yBAAyB;EACzB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,kCAAkC;EAClC,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;AACf;AACA;EACE,kBAAkB;EAClB,kCAAkC;EAClC,YAAY;EACZ,WAAW;AACb","sourcesContent":[".toggleButtonGroup {\n  display: grid;\n  gap: 4px;\n  width: 100%;\n}\n.toggleButton {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 12px 16px;\n  border: 1px solid var(--silver);\n  border-radius: 8px;\n  background-color: var(--white);\n  color: var(--main-font);\n  cursor: pointer;\n  transition: all 0.3s ease;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 18px;\n}\n\n.toggleButton.active {\n  border: 1px solid var(--main-font);\n  color: var(--main-font);\n}\n\n.checkmark {\n  margin-left: 8px;\n}\n\n.toggleButton:hover {\n  background-color: #f5f5f5;\n}\n\n.toggleButton:focus {\n  outline: none;\n}\n.emptyCheckmark {\n  border-radius: 8px;\n  border: 1px solid var(--main-font);\n  height: 16px;\n  width: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggleButtonGroup": `ToggleButtonGroup_toggleButtonGroup__14toY`,
	"toggleButton": `ToggleButtonGroup_toggleButton__wPGyG`,
	"active": `ToggleButtonGroup_active__HeK1O`,
	"checkmark": `ToggleButtonGroup_checkmark__dfedU`,
	"emptyCheckmark": `ToggleButtonGroup_emptyCheckmark__IKix0`
};
export default ___CSS_LOADER_EXPORT___;
