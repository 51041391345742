// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal__wrapper {
  position: fixed;
  z-index: 8;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal {
  background: var(--white);
  min-width: 400px;
  padding: 32px;
  border-radius: 10px;
}
.modal__title {
  color: var(--main-font);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.modal__body {
  color: var(--main-font);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 16px;
  text-align: left;
}

.modal__options {
  display: flex;
  margin-top: 40px;
  justify-content: flex-start;
  gap: 8px;
}

.modal_options_buttons {
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./src/renderer/components/shared/modals/modal-wrapper.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,UAAU;EACV,YAAY;EACZ,WAAW;EACX,oCAAoC;EACpC,MAAM;EACN,OAAO;EACP,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,wBAAwB;EACxB,gBAAgB;EAChB,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,uBAAuB;EACvB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,uBAAuB;EACvB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,2BAA2B;EAC3B,QAAQ;AACV;;AAEA;EACE,aAAa;AACf","sourcesContent":[".modal__wrapper {\n  position: fixed;\n  z-index: 8;\n  height: 100%;\n  width: 100%;\n  background-color: rgba(0, 0, 0, 0.5);\n  top: 0;\n  left: 0;\n  overflow: hidden;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.modal {\n  background: var(--white);\n  min-width: 400px;\n  padding: 32px;\n  border-radius: 10px;\n}\n.modal__title {\n  color: var(--main-font);\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 28px;\n}\n\n.modal__body {\n  color: var(--main-font);\n  font-size: 15px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 20px;\n  margin-top: 16px;\n  text-align: left;\n}\n\n.modal__options {\n  display: flex;\n  margin-top: 40px;\n  justify-content: flex-start;\n  gap: 8px;\n}\n\n.modal_options_buttons {\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
