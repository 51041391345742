import {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react';
import { format, isValid, parse, setHours, setMinutes } from 'date-fns';
import { DayPicker } from 'react-day-picker';

import { CustomInput } from './components';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { IDatePicker } from './types';
import { colors } from '../../../constants/colors';

import styles from './DatePicker.module.css';
import 'react-day-picker/style.css';

const customStyles = {
  weekday: {
    backgroundColor: colors.white,
    background: colors.white,
    padding: '10px',
    borderBottom: `1px solid ${colors.silver}`,
    color: colors.icon_gray,
    fontSize: '15px',
    fontWeight: '400',
  },
};

const classNames = {
  nav: styles.customNav,
  day: styles.customDay,
  today: styles.customToday,
  selected: styles.customSelected,
  caption_label: styles.customCaption,
};

const formatView = 'dd.MM.yyyy';

function CustomDayPicker({
  label,
  onSetDate,
  showTimePicker,
  startMonth,
  endMonth,
  disableInput,
  defaultDate = undefined,
}: IDatePicker) {
  const dialogRef = useRef<HTMLDivElement>(null);
  const dialogId = useId();

  const [month, setMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);
  const [inputValue, setInputValue] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [timeValue, setTimeValue] = useState<string>('00:00');

  const toggleDialog = () => setIsDialogOpen(!isDialogOpen);

  useEffect(() => {
    const handleBodyScroll = (isOpen: boolean) => {
      document.body.style.overflow = isOpen ? 'hidden' : '';
    };
    if (!dialogRef.current) return;
    if (isDialogOpen) {
      handleBodyScroll(true);
      setIsDialogOpen(true);
    } else {
      handleBodyScroll(false);
      setIsDialogOpen(false);
    }
    return () => {
      handleBodyScroll(false);
    };
  }, [isDialogOpen]);

  const clearDate = useCallback(() => {
    setInputValue('');
    setSelectedDate(undefined);
    onSetDate('');
  }, [onSetDate]);
  const handleDayPickerSelect = useCallback(
    (date: Date | undefined) => {
      if (!date) {
        clearDate();
      } else {
        if (timeValue) {
          const [hours, minutes] = timeValue
            .split(':')
            .map((str) => parseInt(str, 10));
          const newDate = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            hours,
            minutes,
          );
          setSelectedDate(newDate);
          setInputValue(format(newDate, formatView));
          onSetDate(newDate);
          return;
        }
        setSelectedDate(date);
        setInputValue(format(date, formatView));
        onSetDate(date);
      }
    },
    [clearDate, onSetDate, timeValue],
  );
  const handleInputChange = useCallback(
    (value: string) => {
      setInputValue(value);
      const parsedDate = parse(value, formatView, new Date());

      if (isValid(parsedDate)) {
        setSelectedDate(parsedDate);
        setMonth(parsedDate);
        onSetDate(parsedDate);
      } else {
        setSelectedDate(undefined);
        onSetDate('');
      }
    },
    [onSetDate],
  );
  const handleTimeChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const time = e.target.value;
    if (!selectedDate) {
      setTimeValue(time);
      return;
    }
    const [hours, minutes] = time.split(':').map((str) => parseInt(str, 10));
    const newSelectedDate = setHours(setMinutes(selectedDate, minutes), hours);
    setSelectedDate(newSelectedDate);
    setTimeValue(time);
    onSetDate(newSelectedDate);
  };

  useOutsideClick(dialogRef, () => setIsDialogOpen(false));

  useEffect(() => {
    if (defaultDate) {
      const parsedDefaultDate =
        typeof defaultDate === 'string'
          ? parse(defaultDate, formatView, new Date())
          : defaultDate;

      if (
        isValid(parsedDefaultDate) &&
        parsedDefaultDate.getTime() !== selectedDate?.getTime()
      ) {
        handleDayPickerSelect(parsedDefaultDate);
      }
    }
  }, [defaultDate, handleDayPickerSelect, selectedDate]);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.dateContainer}>
        {label ? <span className={styles.label}>{label}</span> : null}
        <CustomInput
          handleInputChange={handleInputChange}
          onClick={toggleDialog}
          value={inputValue}
          disableInput={disableInput}
        />
        <div
          className={
            isDialogOpen
              ? styles.calendarContainer
              : styles.calendarContainerHidden
          }
          role="button"
          ref={dialogRef}
          id={dialogId}
        >
          <DayPicker
            month={month}
            onMonthChange={setMonth}
            autoFocus
            mode="single"
            selected={selectedDate}
            onSelect={handleDayPickerSelect}
            styles={customStyles}
            classNames={classNames}
            startMonth={startMonth}
            endMonth={endMonth}
          />
        </div>
      </div>
      {showTimePicker ? (
        <form>
          <input
            className={styles.timeInput}
            type="time"
            value={timeValue}
            onChange={handleTimeChange}
          />
        </form>
      ) : null}
    </div>
  );
}

export default CustomDayPicker;
