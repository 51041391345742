import React from 'react';
import styles from './multi-select-user.module.css';

const MultiSelectUserItem = ({ item, handleDeleteItem }: any) => {
  return (
    <div className={styles.userItem}>
      {item.label}{' '}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        onClick={() => handleDeleteItem(item)}
      >
        <path
          d="M12 4L4 12M4 4L12 12"
          stroke="#0B1215"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default MultiSelectUserItem;
