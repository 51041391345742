import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import classNames from 'classnames';

import { IReleaseModule } from './types';
import Table from '../table/table';
import ReleaseItem from './release-item';
import ArrowSelectIcon from '../../../assets/icons/arrow-select';
import { colors } from '../../../constants/colors';
import AddBtnIcon from '../../../assets/icons/add-btn-icon';
import { RoutesData } from '../../../types/routes/routes';
import styles from './Release.module.css';
import { formatDateForReleaseTitle } from '../../../utils';
import HR from '../hr/hr';

function ReleaseModule({
  tasks,
  releaseId,
  isSomeSearch = false,
}: IReleaseModule) {
  const navigate = useNavigate();

  const [showTasks, setShowTasks] = useState(false);
  const date = useMemo(
    () =>
      tasks[0]?.releaseDate
        ? formatDateForReleaseTitle(tasks[0]?.releaseDate)
        : 'N/A',
    [],
  );
  const releaseName = useMemo(() => tasks[0]?.release?.releaseNumber, []);

  const headers = [
    { title: 'Name' },
    { title: 'Priority' },
    { title: 'Assignee' },
    { title: 'Confluence' },
    { title: 'Redmine' },
    { title: 'Status' },
  ];

  const iconClassName = classNames(styles.icon, {
    [styles.icon__open]: showTasks,
  });
  const titleWrapperClassName = classNames(
    styles.titleWrapper,
    styles.titleWrapperRelease,
  );

  const tasksCount = tasks?.length ? tasks?.length : 'No';
  const handleRedirect = () => {
    navigate(RoutesData.CreateTask, { state: { releaseId } });
  };

  useEffect(() => {
    const releaseStatus = tasks[0]?.release?.status;
    const isShowByDefault = isSomeSearch || releaseStatus !== 'COMPLETED';
    setShowTasks(isShowByDefault);
  }, []);

  return (
    <>
      <ToastContainer />
      <div className={styles.wrapper}>
        <div className={titleWrapperClassName}>
          <div className={styles.titleWrapper} style={{ padding: 0 }}>
            <span
              className={styles.title}
              role="button"
              onClick={() => setShowTasks(!showTasks)}
              style={{
                color: showTasks ? colors.main_blue : colors.main_font,
                cursor: 'pointer',
              }}
            >
              <ArrowSelectIcon
                color={showTasks ? colors.main_blue : colors.main_font}
                className={iconClassName}
              />
              Release {releaseName}
            </span>
            <span className={styles.releaseDate}>Release date: {date} </span>
          </div>
          <div className={styles.endTitleBlock}>
            <span>
              {tasksCount} {tasksCount === 1 ? 'task' : 'tasks'}
            </span>
            <span
              className={styles.addBtn}
              role="button"
              onClick={handleRedirect}
            >
              <AddBtnIcon />
            </span>
          </div>
        </div>
        {showTasks ? (
          <Table headers={headers}>
            {tasks?.length ? (
              tasks?.map((item: any) => {
                return (
                  <ReleaseItem key={item.name + item.priority} item={item} />
                );
              })
            ) : (
              <>no tasks</>
            )}
          </Table>
        ) : null}
      </div>
      <HR color={colors.white} margin="8px 0px" />
    </>
  );
}

export default ReleaseModule;
