import React from 'react';
import { INoReleaseModule } from './types';
import Table from '../table/table';
import ReleaseItem from './release-item';
import { formatDateForReleaseTitle } from '../../../utils';
import HR from '../hr/hr';
import { colors } from '../../../constants/colors';
import styles from './Release.module.css';

function NoReleaseModule({ tasks = [] }: INoReleaseModule): React.JSX.Element {
  const headers = [
    { title: 'Name' },
    { title: 'Priority' },
    { title: 'Assignee' },
    { title: 'Confluence' },
    { title: 'Redmine' },
    { title: 'Status' },
  ];
  const date = tasks[0]?.releaseDate
    ? formatDateForReleaseTitle(tasks[0]?.releaseDate)
    : 'N/A';

  if (!tasks?.length)
    return (
      <>
        <div className={styles.wrapper}>
          <Table headers={headers}>
            <tr>
              <td colSpan={6} className={styles.noItems}>
                <span>No data</span>
              </td>
            </tr>
          </Table>
        </div>
      </>
    );
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.titleWrapper}>
          <span className={styles.title}>Not included in any release</span>
          <span className={styles.releaseDate}>Completion date: {date} </span>
        </div>
        <Table headers={headers}>
          {tasks.map((item: any) => {
            return <ReleaseItem key={item.name + item.priority} item={item} />;
          })}
        </Table>
      </div>
      <HR color={colors.white} margin="8px 0px" />
    </>
  );
}

export default NoReleaseModule;
