// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CreateNewTask_wrapper__N5tzy {
  align-items: center;
  background: var(--white);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px;
  width: 100%;
}
.CreateNewTask_formWrapper__cdg-H {
  align-items: flex-start;
  color: var(--main-font);
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 0 15%;
  width: 100%;
}
.CreateNewTask_formTitle__-posk {
  color: var(--main-font);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.CreateNewTask_row__Cav9R {
  align-items: flex-start;
  display: flex;
  gap: 14px;
  width: 100%;
}
.CreateNewTask_col2__siYAB {
  width: 50%;
}
.CreateNewTask_rowEmployees__yCK0V {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 100%;
}
.CreateNewTask_titleEmployees__6oaZO {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.CreateNewTask_employeesContainer__J0GAC {
  display: flex;
  flex-wrap: wrap;
}
`, "",{"version":3,"sources":["webpack://./src/renderer/screens/Tasks/create-new-task/CreateNewTask.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,wBAAwB;EACxB,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb,WAAW;AACb;AACA;EACE,uBAAuB;EACvB,uBAAuB;EACvB,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,cAAc;EACd,WAAW;AACb;AACA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,uBAAuB;EACvB,aAAa;EACb,SAAS;EACT,WAAW;AACb;AACA;EACE,UAAU;AACZ;AACA;EACE,uBAAuB;EACvB,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,WAAW;AACb;AACA;EACE,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,eAAe;AACjB","sourcesContent":[".wrapper {\n  align-items: center;\n  background: var(--white);\n  border-radius: 8px;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  padding: 16px;\n  width: 100%;\n}\n.formWrapper {\n  align-items: flex-start;\n  color: var(--main-font);\n  display: flex;\n  flex-direction: column;\n  gap: 14px;\n  padding: 0 15%;\n  width: 100%;\n}\n.formTitle {\n  color: var(--main-font);\n  text-align: center;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 28px;\n}\n.row {\n  align-items: flex-start;\n  display: flex;\n  gap: 14px;\n  width: 100%;\n}\n.col2 {\n  width: 50%;\n}\n.rowEmployees {\n  align-items: flex-start;\n  display: flex;\n  flex-direction: column;\n  gap: 14px;\n  width: 100%;\n}\n.titleEmployees {\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 18px;\n}\n.employeesContainer {\n  display: flex;\n  flex-wrap: wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `CreateNewTask_wrapper__N5tzy`,
	"formWrapper": `CreateNewTask_formWrapper__cdg-H`,
	"formTitle": `CreateNewTask_formTitle__-posk`,
	"row": `CreateNewTask_row__Cav9R`,
	"col2": `CreateNewTask_col2__siYAB`,
	"rowEmployees": `CreateNewTask_rowEmployees__yCK0V`,
	"titleEmployees": `CreateNewTask_titleEmployees__6oaZO`,
	"employeesContainer": `CreateNewTask_employeesContainer__J0GAC`
};
export default ___CSS_LOADER_EXPORT___;
