import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import PencilIcon from '../../../assets/icons/pencil';
import EditNote from '../modals/edit-notes';
import { Checkbox, CustomInput, ViewModal } from '../../../components/shared';
import { cutStringByLength } from '../../../utils';
import { ModuleProcess } from '../components/modules-progress/modules-process';
import ClearInputIcon from '../../../assets/icons/clear-input';
import { TOAST_MESSAGES } from '../../../constants';
import styles from './Styles.module.css';

function WaitingItem({
  item,
  isMultipleAction,
  envId,
  getNewDetails,
  handleChange,
  selectedTasks,
  isProdEnv,
}: any): React.JSX.Element {
  const itemId = item?.feature?.id;
  const commentLength = item?.notes?.length;
  const isChecked = selectedTasks?.map((el: any) => el?.id)?.includes(itemId);
  const priority = item?.priority;

  const [editPriority, setEditPriority] = useState(false);
  const [valuePriority, setValuePriority] = useState<
    string | number | undefined
  >(priority);

  const [showNotesEdit, setShowNotesEdit] = useState(false);
  const [showNote, setShowNote] = useState(false);

  const handlePriorityChange = useCallback((v: any) => {
    setValuePriority(v);
  }, []);

  const handlePrioritySet = useCallback(() => {
    if (Number(valuePriority) > 100 || Number(valuePriority) <= 0) {
      toast.error(TOAST_MESSAGES.PRIO_LIMIT);
      return;
    }
    console.log(valuePriority);
    toast.success(TOAST_MESSAGES.SAVED);
    setEditPriority(false);
  }, [valuePriority]);

  return (
    <>
      <tr className={styles.tableItem}>
        {isMultipleAction ? (
          <td style={{ width: '3%' }}>
            <Checkbox
              checked={isChecked}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(e, {
                  id: itemId,
                  modules: item?.modules,
                })
              }
            />
          </td>
        ) : null}
        <td className={styles.hoverTaskInfo}>
          <span className="idTask">#{itemId}:</span>
          <span>{item?.feature?.name}</span>
        </td>
        <td>
          <div className={styles.notes}>
            <span>
              {cutStringByLength(item?.notes, 0, 80, 70)}
              {commentLength && (
                <span
                  className={styles.showNotes}
                  role="button"
                  onClick={() => setShowNote(true)}
                >
                  More
                </span>
              )}
            </span>
            <div className={styles.actionContainer}>
              <PencilIcon
                className={styles.btnIcon}
                onClick={() => setShowNotesEdit(true)}
              />
            </div>
          </div>
        </td>
        <td style={{ minWidth: '133px' }}>
          <div className={styles.priority}>
            {editPriority ? (
              <CustomInput
                adornment={
                  <ClearInputIcon
                    className={styles.closeIcon}
                    onClick={() => {
                      setEditPriority(false);
                      setValuePriority(priority || '');
                    }}
                  />
                }
                label=""
                name="priority"
                value={valuePriority}
                onChange={handlePriorityChange}
                onEnter={handlePrioritySet}
                error=""
                view="endAdornment"
                defaultValue={priority}
                customInputStyle={{ width: '50px' }}
              />
            ) : (
              <span>{valuePriority}</span>
            )}
            <div className={styles.actionContainer}>
              {editPriority ? null : (
                <PencilIcon
                  className={styles.btnIcon}
                  onClick={() => setEditPriority(true)}
                />
              )}
            </div>
          </div>
        </td>
        {isProdEnv ? (
          <td>
            <ModuleProcess modules={item?.modules} />
          </td>
        ) : null}
      </tr>
      {showNotesEdit ? (
        <EditNote
          task={{
            featureId: item?.feature?.id,
            name: item?.feature?.name,
            notes: item?.notes,
          }}
          close={() => setShowNotesEdit(false)}
          envId={envId}
          getNewDetails={getNewDetails}
        />
      ) : null}
      {showNote ? (
        <ViewModal
          text={item?.notes}
          title="Task comment"
          subTitle={`Comment for task #${item?.feature?.id}: ${item?.feature?.name}`}
          callback={() => setShowNote(false)}
        />
      ) : null}
    </>
  );
}

export default WaitingItem;
