import React from 'react';
import { IIcon } from '../../types';
import { colors } from '../../constants/colors';

const TripleFalseIcon = ({
  color = colors.danger,
  onClick = undefined,
  className = '',
}: IIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      onClick={onClick}
      className={className}
    >
      <path
        d="M8.99951 3L2.99951 9M2.99951 3L8.99951 9"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TripleFalseIcon;
