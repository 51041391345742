// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContentPageContainer_wrapper__9YEkd {
  background: var(--lite-background);
  border-radius: 0px 0px 16px 16px;
  display: flex;
  min-height: 90vh;
  justify-content: center;
  margin: 0;
  overflow: auto;
  padding: 16px;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/renderer/components/layouts/ContentPageContainer/ContentPageContainer.module.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,gCAAgC;EAChC,aAAa;EACb,gBAAgB;EAChB,uBAAuB;EACvB,SAAS;EACT,cAAc;EACd,aAAa;EACb,WAAW;AACb","sourcesContent":[".wrapper {\n  background: var(--lite-background);\n  border-radius: 0px 0px 16px 16px;\n  display: flex;\n  min-height: 90vh;\n  justify-content: center;\n  margin: 0;\n  overflow: auto;\n  padding: 16px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ContentPageContainer_wrapper__9YEkd`
};
export default ___CSS_LOADER_EXPORT___;
