import classNames from 'classnames';

import DangerBtn from '../buttons/DangerButton/danger-btn';
import NonBorderBtn from '../buttons/NonBorderBtn/NonBorderBtn';
import PrimaryBtn from '../buttons/PrimaryBtn/PrimaryBtn';
import { IEnvActions } from './types';

import styles from './EnvAction.module.css';

const EnvActionsButtons = ({
  onCancel,
  onAction,
  type,
  isSpecialColorCancelBtn,
  disabled,
}: IEnvActions) => {
  const cancelClassName = classNames(styles.cancelBtn, {
    [styles.alternativeCancelBtn]: isSpecialColorCancelBtn,
  });
  return (
    <div className={styles.btnContainer}>
      {type === 'delete' ? (
        <DangerBtn text="Delete" onClick={onAction} disabled={disabled} />
      ) : (
        <PrimaryBtn text="Apply" onClick={onAction} disabled={disabled} />
      )}

      <NonBorderBtn
        text="Cancel"
        onClick={onCancel}
        withIcon={false}
        classNameBtn={cancelClassName}
      />
    </div>
  );
};
export default EnvActionsButtons;
