// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModuleProcess_moduleWrapper__PG4Pq {
  display: flex;
  gap: 3px;
  height: 34px;
}
.ModuleProcess_moduleItem__Qmz1G:hover {
  border: 1px solid var(--main-blue);
}
.ModuleProcess_tooltipContainer__CIewq {
  position: relative;
  cursor: pointer;
  border-radius: 2px;
  height: 100%;
  width: 8px;
}
.ModuleProcess_tooltipContainer__CIewq:hover {
  border: 1px solid var(--main-blue);
}
.ModuleProcess_tooltipText__s1zYq {
  visibility: hidden;
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--white);
  color: var(--main-font);
  text-align: center;
  padding: 4px 6px;
  border-radius: 4px;
  border: 1px solid var(--light-silver);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 13px;
  font-weight: 500;
  z-index: 4;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.ModuleProcess_tooltipContainer__CIewq:hover .ModuleProcess_tooltipText__s1zYq {
  visibility: visible;
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/renderer/screens/EnvDashboard/components/modules-progress/ModuleProcess.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,QAAQ;EACR,YAAY;AACd;AACA;EACE,kCAAkC;AACpC;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,YAAY;EACZ,UAAU;AACZ;AACA;EACE,kCAAkC;AACpC;AACA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,UAAU;EACV,SAAS;EACT,2BAA2B;EAC3B,8BAA8B;EAC9B,uBAAuB;EACvB,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,qCAAqC;EACrC,0CAA0C;EAC1C,eAAe;EACf,gBAAgB;EAChB,UAAU;EACV,mBAAmB;EACnB,UAAU;EACV,6BAA6B;AAC/B;AACA;EACE,mBAAmB;EACnB,UAAU;AACZ","sourcesContent":[".moduleWrapper {\n  display: flex;\n  gap: 3px;\n  height: 34px;\n}\n.moduleItem:hover {\n  border: 1px solid var(--main-blue);\n}\n.tooltipContainer {\n  position: relative;\n  cursor: pointer;\n  border-radius: 2px;\n  height: 100%;\n  width: 8px;\n}\n.tooltipContainer:hover {\n  border: 1px solid var(--main-blue);\n}\n.tooltipText {\n  visibility: hidden;\n  position: absolute;\n  top: -40px;\n  left: 50%;\n  transform: translateX(-50%);\n  background-color: var(--white);\n  color: var(--main-font);\n  text-align: center;\n  padding: 4px 6px;\n  border-radius: 4px;\n  border: 1px solid var(--light-silver);\n  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);\n  font-size: 13px;\n  font-weight: 500;\n  z-index: 4;\n  white-space: nowrap;\n  opacity: 0;\n  transition: opacity 0.3s ease;\n}\n.tooltipContainer:hover .tooltipText {\n  visibility: visible;\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"moduleWrapper": `ModuleProcess_moduleWrapper__PG4Pq`,
	"moduleItem": `ModuleProcess_moduleItem__Qmz1G`,
	"tooltipContainer": `ModuleProcess_tooltipContainer__CIewq`,
	"tooltipText": `ModuleProcess_tooltipText__s1zYq`
};
export default ___CSS_LOADER_EXPORT___;
