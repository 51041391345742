import { useState } from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';

import { ApproveModal, TextArea } from '../../../components/shared';
import { envApi } from '../../../api/env';
import { HTTP_STATUSES, TOAST_MESSAGES } from '../../../constants';
import { IModalActions } from './types';
import styles from '../expired-view/Styles.module.css';

const EditDeploymentStatus = ({
  close,
  envId = '',
  name,
  initialStatus,
  getNewDetails,
}: IModalActions) => {
  const [touched, setTouched] = useState(false);

  const form = useFormik({
    initialValues: {
      description: initialStatus || '',
      envId,
    },
    onSubmit: async (values) => {
      const body = {
        description: values?.description || ' ',
      };
      const response = await envApi.updateEnvDescription(envId, body);
      if (response?.status === HTTP_STATUSES.ok) {
        toast.success(TOAST_MESSAGES.SAVED);
        getNewDetails();
        close();
      } else {
        toast.error(TOAST_MESSAGES.COMMON_ERROR);
      }
    },
    validateOnChange: false,
  });
  const symbols = form?.values?.description?.length;

  return (
    <ApproveModal
      btnText="Save"
      callback={form.submitForm}
      closeModal={close}
      disabled={form.isSubmitting || !touched}
    >
      <div className={styles.editWrapper}>
        <span className={styles.modal__title}>Edit deployment status</span>
        <div className={styles.description}>
          Enter deployment status for the {name} environment.
        </div>
        <TextArea
          placeholder=""
          onChange={(e) => {
            setTouched(true);
            form.setFieldValue('description', e?.target?.value);
          }}
          name="description"
          label="Status"
          value={form?.values?.description}
          symbols={symbols}
          showCountDown
          maxTextLength={200}
        />
      </div>
    </ApproveModal>
  );
};

export default EditDeploymentStatus;
