import React from 'react';
import ModalWrapper from '../modal-wrapper';
import DangerBtn from '../../buttons/DangerButton/danger-btn';
import OutlineBtn from '../../buttons/OutlinedBtn/OutlineBtn';
import { IDangerModal } from '../types';

const DangerModal: React.FC<IDangerModal> = ({
  title,
  callback,
  closeModal,
  text,
  dangerBtnText = 'Delete',
  disabled = false,
}) => {
  return (
    <ModalWrapper>
      <div className="modal" style={{ width: '400px' }}>
        <div className="modal__title">{title}</div>
        {typeof text === 'string' ? (
          <div
            className="modal__body"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        ) : (
          <div className="modal__body">{text}</div>
        )}

        <div className="modal__options">
          <DangerBtn
            onClick={callback}
            text={dangerBtnText}
            disabled={disabled}
          />
          <OutlineBtn onClick={closeModal} text="Cancel" isSecondary />
        </div>
      </div>
    </ModalWrapper>
  );
};

export default DangerModal;
