import React from 'react';
import { IIcon } from '../../types';

const PencilIcon = ({ className, onClick = undefined }: IIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
      onClick={onClick}
    >
      <g clipPath="url(#clip0_1319_6810)">
        <path
          d="M12.0001 6.66719L9.33344 4.00053M1.66675 14.3339L3.92299 14.0832C4.19865 14.0525 4.33648 14.0372 4.46531 13.9955C4.57961 13.9585 4.68838 13.9063 4.78867 13.8401C4.90171 13.7655 4.99977 13.6675 5.1959 13.4714L14.0001 4.66719C14.7365 3.93081 14.7365 2.73691 14.0001 2.00053C13.2637 1.26415 12.0698 1.26415 11.3334 2.00053L2.52923 10.8047C2.33311 11.0009 2.23505 11.0989 2.16051 11.2119C2.09437 11.3123 2.04209 11.421 2.00509 11.5353C1.96339 11.6641 1.94807 11.8019 1.91744 12.0776L1.66675 14.3339Z"
          stroke="#205DD1"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1319_6810">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.000488281)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PencilIcon;
