// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_wrapper__WMd4R {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 72px;
  padding: 0 24px;
  position: fixed;
  background-color: var(--white);
  width: 100%;
  z-index: 4;
}
.Header_appTitle__--9si {
  color: var(--main-blue);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Header_tabWrapper__ilGjE {
  align-items: center;
  display: flex;
  justify-content: space-between;
  border-radius: 12px;
  background: var(--element-background);
  padding: 4px;
}
.Header_tabItem__zwAxA {
  padding: 10px 24px;
  border-radius: 8px;
  background: transparent;
  color: var(--disabled-font);
  margin: 0;
  width: auto;
}
.Header_tabItem__open__EWvNW {
  background: var(--white);
  color: var(--main-font);
}
.Header_tabItem__zwAxA:hover {
  color: var(--main-font);
}
`, "",{"version":3,"sources":["webpack://./src/renderer/components/shared/main-header/Header.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;EACb,8BAA8B;EAC9B,YAAY;EACZ,eAAe;EACf,eAAe;EACf,8BAA8B;EAC9B,WAAW;EACX,UAAU;AACZ;AACA;EACE,uBAAuB;EACvB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,mBAAmB;EACnB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,qCAAqC;EACrC,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,uBAAuB;EACvB,2BAA2B;EAC3B,SAAS;EACT,WAAW;AACb;AACA;EACE,wBAAwB;EACxB,uBAAuB;AACzB;AACA;EACE,uBAAuB;AACzB","sourcesContent":[".wrapper {\n  align-items: center;\n  display: flex;\n  justify-content: space-between;\n  height: 72px;\n  padding: 0 24px;\n  position: fixed;\n  background-color: var(--white);\n  width: 100%;\n  z-index: 4;\n}\n.appTitle {\n  color: var(--main-blue);\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n}\n.tabWrapper {\n  align-items: center;\n  display: flex;\n  justify-content: space-between;\n  border-radius: 12px;\n  background: var(--element-background);\n  padding: 4px;\n}\n.tabItem {\n  padding: 10px 24px;\n  border-radius: 8px;\n  background: transparent;\n  color: var(--disabled-font);\n  margin: 0;\n  width: auto;\n}\n.tabItem__open {\n  background: var(--white);\n  color: var(--main-font);\n}\n.tabItem:hover {\n  color: var(--main-font);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Header_wrapper__WMd4R`,
	"appTitle": `Header_appTitle__--9si`,
	"tabWrapper": `Header_tabWrapper__ilGjE`,
	"tabItem": `Header_tabItem__zwAxA`,
	"tabItem__open": `Header_tabItem__open__EWvNW`
};
export default ___CSS_LOADER_EXPORT___;
