import React from 'react';
import { IIcon } from '../../types';
import { colors } from '../../constants/colors';

const ClearInputIcon = ({
  color = colors.main_font,
  className = '',
  onClick,
}: IIcon) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM6.45962 5.54038C6.20578 5.28654 5.79422 5.28654 5.54038 5.54038C5.28654 5.79422 5.28654 6.20578 5.54038 6.45962L7.08076 8L5.54038 9.54038C5.28654 9.79422 5.28654 10.2058 5.54038 10.4596C5.79422 10.7135 6.20578 10.7135 6.45962 10.4596L8 8.91924L9.54038 10.4596C9.79422 10.7135 10.2058 10.7135 10.4596 10.4596C10.7135 10.2058 10.7135 9.79422 10.4596 9.54038L8.91924 8L10.4596 6.45962C10.7135 6.20578 10.7135 5.79422 10.4596 5.54038C10.2058 5.28654 9.79422 5.28654 9.54038 5.54038L8 7.08076L6.45962 5.54038Z"
        fill={color}
      />
    </svg>
  );
};

export default ClearInputIcon;
