import React, { useState } from 'react';
import './Switch.css';
import TripleTrueIcon from '../../../assets/icons/tripleTrue';
import TripleFalseIcon from '../../../assets/icons/tripleFalse';

interface SwitchProps {
  initialState?: boolean;
  label?: string;
  handleSwitch?: (val: boolean) => void;
  details?: string;
}

const Switch: React.FC<SwitchProps> = ({
  initialState = false,
  label,
  handleSwitch = undefined,
  details,
}) => {
  const [isActive, setIsActive] = useState<boolean>(initialState);

  const toggleSwitch = () => {
    setIsActive(!isActive);
    handleSwitch && handleSwitch(!isActive);
  };

  return (
    <div className="switchWrapper">
      {label ? <span>{label}</span> : null}

      <div className="detailsContainer">
        <div
          className={`switch ${isActive ? 'active' : 'inactive'}`}
          onClick={toggleSwitch}
        >
          <div className="switch-icon">
            {isActive ? (
              <TripleTrueIcon />
            ) : (
              <TripleFalseIcon className="falseIcon" />
            )}
          </div>
        </div>
        {details ? <span>{details}</span> : null}
      </div>
    </div>
  );
};

export default Switch;
