// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CheckBox_checkboxContainer__3vlk4 {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  border-radius: 4px;
}
.CheckBox_checkboxContainerDisabled__Vw4SS {
  cursor: not-allowed;
}
.CheckBox_checkboxInput__uWpTB {
  min-width: 16px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  accent-color: var(--main-blue);
}

.CheckBox_checkboxLabel__meV-T {
  font-size: 14px;
  color: var(--main-font);
  word-break: break-all;
}
.CheckBox_checkboxLabelDisabled__9dv6z {
  color: var(--disabled-font);
}
`, "",{"version":3,"sources":["webpack://./src/renderer/components/shared/check-box/CheckBox.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,QAAQ;EACR,kBAAkB;AACpB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,WAAW;EACX,YAAY;EACZ,eAAe;EACf,8BAA8B;AAChC;;AAEA;EACE,eAAe;EACf,uBAAuB;EACvB,qBAAqB;AACvB;AACA;EACE,2BAA2B;AAC7B","sourcesContent":[".checkboxContainer {\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  gap: 8px;\n  border-radius: 4px;\n}\n.checkboxContainerDisabled {\n  cursor: not-allowed;\n}\n.checkboxInput {\n  min-width: 16px;\n  width: 16px;\n  height: 16px;\n  cursor: pointer;\n  accent-color: var(--main-blue);\n}\n\n.checkboxLabel {\n  font-size: 14px;\n  color: var(--main-font);\n  word-break: break-all;\n}\n.checkboxLabelDisabled {\n  color: var(--disabled-font);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkboxContainer": `CheckBox_checkboxContainer__3vlk4`,
	"checkboxContainerDisabled": `CheckBox_checkboxContainerDisabled__Vw4SS`,
	"checkboxInput": `CheckBox_checkboxInput__uWpTB`,
	"checkboxLabel": `CheckBox_checkboxLabel__meV-T`,
	"checkboxLabelDisabled": `CheckBox_checkboxLabelDisabled__9dv6z`
};
export default ___CSS_LOADER_EXPORT___;
