import { useCallback, useEffect, useState } from 'react';
import { groupByDate, groupByKey } from '../../../utils';
import { projectsApi } from '../../../api/projects';
import { getAuthUserRequest } from '../../../api/users';
import { taskApi } from '../../../api/task';
import { HTTP_STATUSES } from '../../../constants';
import {
  NoReleaseModule,
  ReleaseModule,
  Select,
} from '../../../components/shared';
import { isObjectEmpty } from '../../../utils/objects/isObjectEmpty';
import Loading from '../../../components/shared/loading/main-loading';

import styles from './Planning.module.css';

const PlanningTable = ({ search, searchByString }: any) => {
  const [tasksWithDate, setTasksWithDate] = useState<any[]>([]);
  const [tasksWithoutDate, setTasksWithoutDate] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<any[]>([]);
  const [filterByUser, setFilterByUser] = useState<any>('all');

  const getUsersList = useCallback(async () => {
    const params: any = {
      skip: 0,
      take: 100,
      sort: 'id',
      order: 'ASC',
    };
    const res = await projectsApi.getProjectsUsers(params);
    const resUser = await getAuthUserRequest();
    const userMe = resUser?.data
      ? {
          value: resUser?.data?.id,
          label: 'Me',
        }
      : null;

    if (res?.status === HTTP_STATUSES.ok) {
      const projectUsers = [
        {
          value: 'all',
          label: 'All',
        },
        userMe,
      ].concat(
        res?.data?.list?.map((user: any) => {
          return {
            value: user?.id,
            label: `${user?.user?.firstName} ${user?.user?.lastName}`,
          };
        }),
      );
      setUsers(projectUsers);
    }
  }, []);

  const getData = useCallback(
    async (filter = {}) => {
      setLoading(true);
      try {
        const params: any = {
          skip: 0,
          take: 100,
          sort: 'releaseDate',
          order: 'ASC',
          ...(search && { search: JSON.stringify({ name: search }) }),
          ...(filter && { filter: JSON.stringify({ ...filter }) }),
        };
        const response = await taskApi.getTasksList(params);

        if (response?.status === HTTP_STATUSES.ok) {
          const filterNoDate = groupByKey(
            response?.data?.list.filter((task: any) => !task?.releaseDate),
            'releaseId',
          );
          setTasksWithoutDate(filterNoDate);

          const filterWithDate = groupByDate(
            response?.data?.list.filter((task: any) => task?.releaseDate),
            'releaseDate',
          );
          const tasksByDateRelease = filterWithDate
            .map((el: any) => {
              return groupByKey(el?.items, 'releaseId');
            })
            .reverse();
          setTasksWithDate(tasksByDateRelease);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [search],
  );

  useEffect(() => {
    getData();
  }, [searchByString]);

  useEffect(() => {
    getUsersList();
  }, [getUsersList]);

  useEffect(() => {
    handleFilterByUser(undefined);
  }, [searchByString]);

  const handleFilterByUser = (user: any) => {
    if (!user) {
      setFilterByUser('all');
      getData({});
      return;
    }
    const { value } = user;
    setFilterByUser(value);
    const filterId = value === 'all' ? {} : { 'assignees.assigneeId': value };
    getData(filterId);
  };

  const isSomeSearch = search?.length > 0;

  return (
    <div className={styles.wrapper}>
      <div className={styles.searchRow}>
        <span>Filter:</span>
        <div style={{ width: '20%' }}>
          <Select
            showAdditionalInfoInSelect
            options={users}
            onChange={handleFilterByUser}
            emptyText=""
            value={filterByUser}
            showClear
          />
        </div>
      </div>
      {loading ? (
        <div className={styles.loadingContainer}>
          <Loading />
        </div>
      ) : (
        <>
          {tasksWithDate?.length || !isObjectEmpty(tasksWithoutDate) ? (
            <>
              {Object.entries(tasksWithoutDate)
                .reverse()
                .map((item: any) => {
                  if (item[0] === 'no_release') {
                    return <NoReleaseModule key={item.name} tasks={item[1]} />;
                  } else {
                    return (
                      <ReleaseModule
                        key={item.name}
                        tasks={item[1]}
                        releaseId={item[0]}
                        isSomeSearch={isSomeSearch}
                      />
                    );
                  }
                })}
              {tasksWithDate.map((item: any) => {
                const taskObj = Object.entries(item);
                return taskObj.map((task: any) => {
                  if (task[0] === 'no_release') {
                    return <NoReleaseModule key={task[0]} tasks={task[1]} />;
                  } else {
                    return (
                      <ReleaseModule
                        key={task[0]}
                        tasks={task[1]}
                        releaseId={task[0]}
                        isSomeSearch={isSomeSearch}
                      />
                    );
                  }
                });
              })}
            </>
          ) : (
            <div>
              <NoReleaseModule />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PlanningTable;
