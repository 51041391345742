import classNames from 'classnames';

import { IDangerBtn } from './types';
import styles from './DangerBtn.module.css';

const DangerBtn = ({ text, onClick, disabled }: IDangerBtn) => {
  const clickHandler = () => {
    if (disabled) return;
    onClick();
  };

  const btnClassName = classNames(styles.danger__btn, {
    [styles.disabled__danger__btn]: disabled,
  });

  return (
    <button className={btnClassName} onClick={clickHandler}>
      {text}
    </button>
  );
};

export default DangerBtn;
