// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Login_wrapper__w6Goe {
  align-items: center;
  border-radius: 16px;
  background: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
  padding: 32px;
  width: 360px;
}
.Login_appTitle__NsrqP {
  color: var(--main-blue);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  width: 100%;
}
.Login_welcomeMessage__Rh1Mb {
  color: var(--main-font);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  width: auto;
}
`, "",{"version":3,"sources":["webpack://./src/renderer/containers/login/Login.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,wBAAwB;EACxB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,SAAS;EACT,aAAa;EACb,YAAY;AACd;AACA;EACE,uBAAuB;EACvB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,WAAW;AACb;AACA;EACE,uBAAuB;EACvB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,WAAW;AACb","sourcesContent":[".wrapper {\n  align-items: center;\n  border-radius: 16px;\n  background: var(--white);\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  gap: 32px;\n  padding: 32px;\n  width: 360px;\n}\n.appTitle {\n  color: var(--main-blue);\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n  text-align: center;\n  width: 100%;\n}\n.welcomeMessage {\n  color: var(--main-font);\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 18px;\n  text-align: center;\n  width: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Login_wrapper__w6Goe`,
	"appTitle": `Login_appTitle__NsrqP`,
	"welcomeMessage": `Login_welcomeMessage__Rh1Mb`
};
export default ___CSS_LOADER_EXPORT___;
