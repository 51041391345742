import React from 'react';
import PropTypes from 'prop-types';

import { colors } from '../../../constants/colors';

import styles from './loading.module.css';

const Loading = ({ color = colors.main_font }) => {
  return (
    <div className={styles.icon}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.25 8C13.25 10.8995 10.8995 13.25 8 13.25C5.1005 13.25 2.75 10.8995 2.75 8C2.75 5.1005 5.1005 2.75 8 2.75"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

Loading.propTypes = {
  color: PropTypes.string,
};

export default Loading;
