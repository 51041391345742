import React from 'react';

const CheckMark = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM11.4596 6.45962C11.7135 6.20578 11.7135 5.79422 11.4596 5.54038C11.2058 5.28654 10.7942 5.28654 10.5404 5.54038L7 9.08076L5.45962 7.54038C5.20578 7.28654 4.79422 7.28654 4.54038 7.54038C4.28654 7.79422 4.28654 8.20578 4.54038 8.45962L6.54038 10.4596C6.66228 10.5815 6.82761 10.65 7 10.65C7.17239 10.65 7.33772 10.5815 7.45962 10.4596L11.4596 6.45962Z"
        fill="#205DD1"
      />
    </svg>
  );
};

export default CheckMark;
