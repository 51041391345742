import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { envApi } from '../../../api/env';
import TripleSwitch, {
  labels,
} from '../../../components/shared/triple-switch/TripleSwitch';
import { getStatus, statusForModule } from '../constants';
import { HTTP_STATUSES, TOAST_MESSAGES } from '../../../constants';
import { Checkbox, Switch } from '../../../components/shared';
import { colors } from '../../../constants/colors';
import { formatDate } from '../../../utils';

import styles from './Styles.module.css';

function TaskItem({
  item,
  envId,
  isMultipleAction,
  handleChange,
  isProdEnv,
  selectedTasks,
}: any): React.JSX.Element {
  const itemId = item?.feature?.id;
  const [currentTaskStatus, setCurrentTaskStatus] = useState(false);
  const status = getStatus(item?.status);
  const isResolved = status === 'Resolved';
  const modules = item?.modules;

  const handleModuleStatus = useCallback(
    async (val: any, moduleValue: any) => {
      const { id, name } = moduleValue;
      const body = {
        featureId: item?.feature?.id,
        module: {
          id,
          name,
          state: val?.value,
        },
      };
      const response = await envApi.updateTaskModuleStatus(envId, body);
      if (response?.status === HTTP_STATUSES.ok) {
        toast.success(TOAST_MESSAGES.MODULE_CHANGED);
      }
    },
    [envId],
  );
  const handleTaskStatus = useCallback(
    async (value: boolean) => {
      const body = {
        featureId: Number(item?.feature?.id),
        status: value ? 'RESOLVED' : 'NEW',
      };
      const response = await envApi.updateTaskStatus(envId, body);
      if (response?.status === HTTP_STATUSES.ok) {
        toast.success(TOAST_MESSAGES.STATUS_CHANGED);
        setCurrentTaskStatus(value);
      }
    },
    [envId],
  );

  useEffect(() => {
    setCurrentTaskStatus(isResolved);
  }, []);

  const deployedDate = formatDate(item?.updatedAt);

  const isChecked = selectedTasks
    ? selectedTasks?.map((el: any) => el?.id)?.includes(itemId)
    : false;

  return (
    <tr className={styles.tableItem}>
      {isProdEnv ? (
        <td style={{ textAlign: 'center' }}>
          {deployedDate?.date} <br />
          {deployedDate?.time}
        </td>
      ) : null}
      {isMultipleAction ? (
        <td style={{ width: '3%' }}>
          <Checkbox
            checked={isChecked}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e, {
                id: itemId,
                modules: item?.modules,
              })
            }
          />
        </td>
      ) : null}
      <td className={styles.hoverTaskInfo}>
        <span className="idTask">#{itemId}:</span>
        <span>{item?.feature?.name}</span>
      </td>
      {modules.map((m: any) => {
        const moduleStatus = statusForModule(m?.state);
        const defaultValue =
          labels
            .find((label: any) => label?.name === moduleStatus)
            ?.name?.toLowerCase() || 'off';
        return (
          <td key={m?.id}>
            <div className={styles.item}>
              <TripleSwitch
                initialState={defaultValue}
                onChange={(value: any) => handleModuleStatus(value, m)}
              />
            </div>
          </td>
        );
      })}
      {isProdEnv ? null : (
        <td>
          <div
            className={styles.status}
            style={{
              color: isResolved ? colors.main_font : colors.disabled_font,
            }}
          >
            <Switch
              initialState={isResolved}
              handleSwitch={handleTaskStatus}
              details={currentTaskStatus ? 'Resolved' : 'Unresolved'}
            />
          </div>
        </td>
      )}
    </tr>
  );
}

export default TaskItem;
