import React from 'react';
import { colors } from '../../../constants/colors';
import { IIcon } from '../../../types';

const EnvQa = ({ color = colors.icon_gray }: IIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M5.50016 3.00004C5.50016 2.07957 6.24636 1.33337 7.16683 1.33337C8.0873 1.33337 8.8335 2.07957 8.8335 3.00004V4.00004H9.50016C10.432 4.00004 10.898 4.00004 11.2656 4.15228C11.7556 4.35527 12.145 4.74462 12.3479 5.23467C12.5002 5.60221 12.5002 6.06816 12.5002 7.00004H13.5002C14.4206 7.00004 15.1668 7.74624 15.1668 8.66671C15.1668 9.58717 14.4206 10.3334 13.5002 10.3334H12.5002V11.4667C12.5002 12.5868 12.5002 13.1468 12.2822 13.5747C12.0904 13.951 11.7845 14.257 11.4082 14.4487C10.9803 14.6667 10.4203 14.6667 9.30016 14.6667H8.8335V13.5C8.8335 12.6716 8.1619 12 7.3335 12C6.50507 12 5.8335 12.6716 5.8335 13.5V14.6667H5.0335C3.91339 14.6667 3.35334 14.6667 2.92552 14.4487C2.54919 14.257 2.24323 13.951 2.05148 13.5747C1.8335 13.1468 1.8335 12.5868 1.8335 11.4667V10.3334H2.8335C3.75397 10.3334 4.50016 9.58717 4.50016 8.66671C4.50016 7.74624 3.75397 7.00004 2.8335 7.00004H1.8335C1.8335 6.06816 1.8335 5.60221 1.98574 5.23467C2.18872 4.74462 2.57808 4.35527 3.06813 4.15228C3.43567 4.00004 3.90162 4.00004 4.8335 4.00004H5.50016V3.00004Z"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EnvQa;
