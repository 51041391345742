// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PrimaryBtn_primary_btn__lot\\+X {
  align-items: center;
  align-self: center;
  background: var(--main-blue);
  border-radius: 8px;
  border: 1px solid var(--main-blue);
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  justify-content: center;
  line-height: 18px;
  min-width: 90px;
  padding: 10px 24px;
  text-align: center;
}
.PrimaryBtn_primary_btn__lot\\+X:hover {
  border-color: var(--dark-blue);
  background: var(--dark-blue);
}
.PrimaryBtn_primary_btn_disabled__Aedn\\+,
.PrimaryBtn_primary_btn_disabled__Aedn\\+:hover {
  background: var(--light-gray);
  border: 1px solid var(--light-gray);
  color: var(--medium-gray);
  cursor: not-allowed;
}
`, "",{"version":3,"sources":["webpack://./src/renderer/components/shared/buttons/PrimaryBtn/PrimaryBtn.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,4BAA4B;EAC5B,kBAAkB;EAClB,kCAAkC;EAClC,mBAAmB;EACnB,eAAe;EACf,aAAa;EACb,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,uBAAuB;EACvB,iBAAiB;EACjB,eAAe;EACf,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,8BAA8B;EAC9B,4BAA4B;AAC9B;AACA;;EAEE,6BAA6B;EAC7B,mCAAmC;EACnC,yBAAyB;EACzB,mBAAmB;AACrB","sourcesContent":[".primary_btn {\n  align-items: center;\n  align-self: center;\n  background: var(--main-blue);\n  border-radius: 8px;\n  border: 1px solid var(--main-blue);\n  color: var(--white);\n  cursor: pointer;\n  display: flex;\n  font-size: 15px;\n  font-style: normal;\n  font-weight: 500;\n  justify-content: center;\n  line-height: 18px;\n  min-width: 90px;\n  padding: 10px 24px;\n  text-align: center;\n}\n.primary_btn:hover {\n  border-color: var(--dark-blue);\n  background: var(--dark-blue);\n}\n.primary_btn_disabled,\n.primary_btn_disabled:hover {\n  background: var(--light-gray);\n  border: 1px solid var(--light-gray);\n  color: var(--medium-gray);\n  cursor: not-allowed;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary_btn": `PrimaryBtn_primary_btn__lot+X`,
	"primary_btn_disabled": `PrimaryBtn_primary_btn_disabled__Aedn+`
};
export default ___CSS_LOADER_EXPORT___;
