import React from 'react';
import { IIcon } from '../../types';
import { colors } from '../../constants/colors';

const TripleOffIcon = ({
  color = colors.disabled_font,
  onClick = undefined,
  className = '',
}: IIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      onClick={onClick}
      className={className}
    >
      <g clipPath="url(#clip0_1256_14620)">
        <path
          d="M5.99951 1.00049V11.0031"
          stroke={color}
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1256_14620">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(-0.000488281 0.000488281)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TripleOffIcon;
