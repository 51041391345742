// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Modal_modal__Q\\+oNh {
  background: var(--white);
  width: 460px;
  padding: 24px;
  border-radius: 10px;
  position: relative;
}
.Modal_icon__gpfRF {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/renderer/components/shared/modals/view-modal/Modal.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,eAAe;AACjB","sourcesContent":[".modal {\n  background: var(--white);\n  width: 460px;\n  padding: 24px;\n  border-radius: 10px;\n  position: relative;\n}\n.icon {\n  position: absolute;\n  top: 16px;\n  right: 16px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `Modal_modal__Q+oNh`,
	"icon": `Modal_icon__gpfRF`
};
export default ___CSS_LOADER_EXPORT___;
