import React from 'react';
import { colors } from '../../../constants/colors';
import { IIcon } from '../../../types';

const PlanningIcon = ({ color = colors.icon_gray }: IIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M4.50065 9.99992L5.83398 11.3333L8.83398 8.33325M5.83398 5.33325V3.46659C5.83398 2.71985 5.83398 2.34648 5.97931 2.06127C6.10714 1.81038 6.31111 1.60641 6.562 1.47858C6.84721 1.33325 7.22058 1.33325 7.96732 1.33325H13.034C13.7807 1.33325 14.1541 1.33325 14.4393 1.47858C14.6902 1.60641 14.8942 1.81038 15.022 2.06127C15.1673 2.34648 15.1673 2.71985 15.1673 3.46659V8.53325C15.1673 9.27998 15.1673 9.65338 15.022 9.93858C14.8942 10.1895 14.6902 10.3935 14.4393 10.5213C14.1541 10.6666 13.7807 10.6666 13.034 10.6666H11.1673M3.96732 14.6666H9.03398C9.78072 14.6666 10.1541 14.6666 10.4393 14.5213C10.6902 14.3935 10.8942 14.1895 11.022 13.9386C11.1673 13.6534 11.1673 13.28 11.1673 12.5333V7.46658C11.1673 6.71985 11.1673 6.34648 11.022 6.06127C10.8942 5.81038 10.6902 5.6064 10.4393 5.47858C10.1541 5.33325 9.78072 5.33325 9.03398 5.33325H3.96732C3.22058 5.33325 2.84721 5.33325 2.562 5.47858C2.31111 5.6064 2.10714 5.81038 1.97931 6.06127C1.83398 6.34648 1.83398 6.71985 1.83398 7.46658V12.5333C1.83398 13.28 1.83398 13.6534 1.97931 13.9386C2.10714 14.1895 2.31111 14.3935 2.562 14.5213C2.84721 14.6666 3.22058 14.6666 3.96732 14.6666Z"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PlanningIcon;
