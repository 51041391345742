import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useUserQuery } from '../../../hooks/useQuery';

function PrivateRoute() {
  const { isSuccess, isLoading } = useUserQuery();

  if (isLoading) {
    return <div>Loading</div>;
  }

  return isSuccess ? <Outlet /> : <Navigate to="/login" />;
}

export default PrivateRoute;
