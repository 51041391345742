import CalendarIcon from '../../../../assets/icons/calendar-icon';
import { colors } from '../../../../constants/colors';
import CustomInput from '../../inputs';

import styles from '../DatePicker.module.css';

const DateInput = (props: any) => {
  const {
    onClick,
    handleClearDate,
    value,
    showClear = false,
    handleInputChange,
    disableInput,
  } = props;

  return (
    <>
      <CustomInput
        adornment={
          <div
            className={styles.custom_input__controls}
            onClick={
              showClear
                ? (event: any) => {
                    event.stopPropagation();
                    handleClearDate();
                  }
                : undefined
            }
            role="button"
          >
            <CalendarIcon
              color={disableInput ? colors.icon_gray : colors.main_font}
              onClick={disableInput ? undefined : onClick}
            />
          </div>
        }
        label=""
        pattern="\d{2}\.\d{2}\.\d{4}"
        placeHolder="DD.MM.YYYY"
        name="releaseDate"
        value={value}
        onChange={handleInputChange}
        error=""
        view="endAdornment"
        disabled={disableInput}
      />
    </>
  );
};

export default DateInput;
