import React from 'react';
import { IIcon } from '../../types';
import { colors } from '../../constants/colors';

const TripleTrueIcon = ({
  color = colors.main_font,
  onClick = undefined,
  className = '',
}: IIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      onClick={onClick}
      className={className}
    >
      <path
        d="M9.99951 3.00024L4.49951 8.50024L1.99951 6.00024"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TripleTrueIcon;
