// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading_icon__b3n2f {
    animation: loading_rotate__-SK3R 2s infinite;
    width: 16px;
    height: 16px;
}

@keyframes loading_rotate__-SK3R {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
`, "",{"version":3,"sources":["webpack://./src/renderer/components/shared/loading/loading.module.css"],"names":[],"mappings":"AAAA;IACI,4CAA6B;IAC7B,WAAW;IACX,YAAY;AAChB;;AAEA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".icon {\n    animation: rotate 2s infinite;\n    width: 16px;\n    height: 16px;\n}\n\n@keyframes rotate {\n    0% {\n        transform: rotate(0deg);\n    }\n    100% {\n        transform: rotate(360deg);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `loading_icon__b3n2f`,
	"rotate": `loading_rotate__-SK3R`
};
export default ___CSS_LOADER_EXPORT___;
