import { DangerModal } from '../../../components/shared';

const ReleaseConflict = ({ callback, close }: any) => {
  return (
    <DangerModal
      callback={callback}
      closeModal={close}
      text="The release being migrated is different from the release in the target environment, you can either have the target environment reassign the release or cancel the migration."
      title="Release conflict"
      dangerBtnText="Reassign release"
    />
  );
};

export default ReleaseConflict;
