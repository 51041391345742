import React, { memo, useCallback, useEffect, useState } from 'react';
import { Checkbox, Select } from '../../../../components/shared';
import { TASK_STATUS } from '../../constants';

import styles from './MoveTaskDetails.module.css';

type Env = {
  label: string;
  value: string | number;
};

type IMoveTaskDetails = {
  type: 'row' | 'column-reverse';
  envs?: Env[];
  currentEnv?: string | number;
  currentList?: 'waiting' | 'active';
  addWidthSelect?: boolean;
  form?: any;
  isProduction?: boolean;
};

const MoveTaskDetails = ({
  type,
  envs,
  currentEnv,
  currentList,
  addWidthSelect,
  form,
  isProduction,
}: IMoveTaskDetails) => {
  const isWaitingCurrentList = currentList === 'waiting';

  const [envList, setEnvList] = useState<any>();
  const [envsOptions, setEnvsOptions] = useState<any>([]);
  const [isWaitingTargetList, setIsWaitingTargetList] = useState<any>(false);

  const handleEnvList = useCallback(
    (selected: {
      value: string | number;
      additionalInfo?: any;
      isProduction: boolean;
    }) => {
      setEnvList(selected?.value);
      form.setFieldValue('targetEnv', selected?.value);
      form.setFieldValue('targetEnvDetails', selected?.additionalInfo);
      if (String(selected?.value).includes('WL') && !selected?.isProduction) {
        setIsWaitingTargetList(true);
        form.setFieldValue('saveFeatureModuleStatuses', false);
      } else {
        setIsWaitingTargetList(false);
      }
    },
    [],
  );
  useEffect(() => {
    if (isProduction) {
      const filterEnvsProd = envs?.filter((el: any) => el?.isProduction);
      const option = filterEnvsProd?.map((el: any) => ({
        ...el,
        label: 'Production history',
        additionalInfo: {
          filter: TASK_STATUS.NEW,
          envId: el.value,
        },
      }));
      setEnvsOptions(option);
      option && handleEnvList(option[0]);
      return;
    }
    const options = envs?.flatMap((el: any) => {
      const baseEnv = {
        ...el,
        label: el?.isProduction ? 'Production history' : el.label,
        additionalInfo: { filter: TASK_STATUS.NEW, envId: el.value },
      };
      const waitingEnv = {
        ...el,
        label: `${el.label} WL`,
        value: `${el.value}WL`,
        additionalInfo: {
          filter: TASK_STATUS.WAITING,
          envId: el.value,
        },
      };
      return currentEnv === el.value
        ? [isWaitingCurrentList ? baseEnv : waitingEnv]
        : [baseEnv, waitingEnv];
    });
    setEnvsOptions(options);
  }, [currentEnv, currentList, isProduction]);

  return (
    <div
      style={{
        flexDirection: type,
        alignItems: type.includes('column') ? 'flex-start' : 'center',
      }}
      className={styles.containerDetails}
    >
      <div className={styles.checkBoxContainer}>
        {isProduction ? null : (
          <Checkbox
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              form.setFieldValue('overwrite', e.target.checked);
            }}
            checked={form?.values?.overwrite}
            label="Overwrite the current task list in the target environment."
          />
        )}
        <div style={{ marginBottom: '8px' }} />
        {isWaitingCurrentList && !isProduction ? null : (
          <Checkbox
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              form.setFieldValue('saveFeatureModuleStatuses', e.target.checked);
            }}
            checked={form?.values?.saveFeatureModuleStatuses}
            label="Save modules statuses."
            disabled={isWaitingTargetList}
          />
        )}
        {isWaitingCurrentList ? null : <div style={{ marginBottom: '8px' }} />}
      </div>

      <div
        className={styles.searchRow}
        style={{
          marginBottom: addWidthSelect ? 0 : '8px',
          width: addWidthSelect ? '16%' : '100%',
        }}
      >
        {isProduction ? (
          <div className={styles.defaultEnvContainer}>
            Move to
            <span style={{ fontWeight: '500', marginLeft: '4px' }}>
              {envsOptions[0]?.label}
            </span>
          </div>
        ) : (
          <Select
            showAdditionalInfoInSelect
            options={envsOptions}
            onChange={handleEnvList}
            emptyText=""
            value={envList}
            additionalInfoText="Move to "
            name="targetEnv"
          />
        )}
      </div>
    </div>
  );
};

export default memo(MoveTaskDetails);
