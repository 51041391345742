import React from 'react';
import className from 'classnames';
import { IOutlinedBtn } from './types';
import styles from './OutlineBtn.module.css';

const OutlineBtn = ({
  classNameBtn = '',
  text,
  onClick,
  disabled = false,
  isSecondary = false,
}: IOutlinedBtn) => {
  const btnClassName = className(styles.outlined_btn, {
    [styles.outlined_btn_secondary]: isSecondary,
    [classNameBtn]: classNameBtn,
  });

  return (
    <button
      className={btnClassName}
      onClick={
        !disabled
          ? onClick
          : (e) => {
              e.preventDefault();
            }
      }
    >
      {text}
    </button>
  );
};

export default OutlineBtn;
