// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RoundIcon_roundWrapper__-wuzN {
  align-items: center;
  border-radius: 24px;
  border: 1px solid rgba(11, 18, 21, 0.05);
  display: flex;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/renderer/components/shared/round-icon/RoundIcon.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,wCAAwC;EACxC,aAAa;EACb,uBAAuB;AACzB","sourcesContent":[".roundWrapper {\n  align-items: center;\n  border-radius: 24px;\n  border: 1px solid rgba(11, 18, 21, 0.05);\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"roundWrapper": `RoundIcon_roundWrapper__-wuzN`
};
export default ___CSS_LOADER_EXPORT___;
