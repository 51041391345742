// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RadioButton_radioButtonGroup__rsgvC {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.RadioButton_leftSide__jVik3 {
  display: flex;
  align-items: center;
  gap: 8px;
}
.RadioButton_radioButton__qojTs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--white);
  color: var(--main-font);
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.RadioButton_checkmark__iVcE0 {
  margin-left: 8px;
}
.RadioButton_radioButton__qojTs:focus {
  outline: none;
}
.RadioButton_emptyCheckmark__CV0ja {
  border-radius: 8px;
  border: 1px solid var(--main-font);
  height: 16px;
  width: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/renderer/components/shared/radio-button/RadioButton.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,8BAA8B;EAC9B,uBAAuB;EACvB,eAAe;EACf,yBAAyB;EACzB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,aAAa;AACf;AACA;EACE,kBAAkB;EAClB,kCAAkC;EAClC,YAAY;EACZ,WAAW;AACb","sourcesContent":[".radioButtonGroup {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n.leftSide {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n.radioButton {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  background-color: var(--white);\n  color: var(--main-font);\n  cursor: pointer;\n  transition: all 0.3s ease;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 18px;\n}\n.checkmark {\n  margin-left: 8px;\n}\n.radioButton:focus {\n  outline: none;\n}\n.emptyCheckmark {\n  border-radius: 8px;\n  border: 1px solid var(--main-font);\n  height: 16px;\n  width: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radioButtonGroup": `RadioButton_radioButtonGroup__rsgvC`,
	"leftSide": `RadioButton_leftSide__jVik3`,
	"radioButton": `RadioButton_radioButton__qojTs`,
	"checkmark": `RadioButton_checkmark__iVcE0`,
	"emptyCheckmark": `RadioButton_emptyCheckmark__CV0ja`
};
export default ___CSS_LOADER_EXPORT___;
