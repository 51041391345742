import { useFormik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import {
  ApproveModal,
  RadioButton,
  ToggleButtonGroup,
} from '../../../components/shared';
import { envApi } from '../../../api/env';
import { HTTP_STATUSES, TOAST_MESSAGES } from '../../../constants';
import Loading from '../../../components/shared/loading/main-loading';
import { formatDate } from '../../../utils';
import { IModalActions } from './types';
import styles from '../expired-view/Styles.module.css';

const defaultEnvs = [{ label: '', value: '' }];

const Restore = ({
  close,
  envId,
  envs = defaultEnvs,
  getNewDetails,
  isProdEnv,
}: IModalActions) => {
  const [envsSnapshotsList, setEnvsSnapshotsList] = useState<any>([]);
  const [snapshotsLoading, setSnapshotsLoading] = useState(false);
  const [touched, setTouched] = useState(false);

  const prodEnv = useMemo(
    () => envs?.filter((el: any) => el?.isProduction),
    [envs],
  );

  const form = useFormik({
    initialValues: {
      fromEnvId: envId,
      snapshotId: '',
    },
    onSubmit: async (values) => {
      const body = {
        snapshotId: values.snapshotId,
      };
      const response = await envApi.restoreEnv(envId, body);
      if (response?.status === HTTP_STATUSES.ok) {
        toast.success(TOAST_MESSAGES.RESTORED);
        getNewDetails(true);
        close();
      } else {
        toast.error(TOAST_MESSAGES.COMMON_ERROR);
      }
    },
    validateOnChange: false,
  });

  const getSnapshotsData = async () => {
    setEnvsSnapshotsList([]);
    setSnapshotsLoading(true);
    const fromEnvId = isProdEnv
      ? prodEnv?.[0]?.value
      : form?.values.fromEnvId || envs?.[0]?.value;
    const params: any = {
      skip: 0,
      take: 100,
      sort: 'createdAt',
      order: 'DESC',
      filter: JSON.stringify({ environmentId: fromEnvId }),
    };
    const response = await envApi.getSnapshotsList(fromEnvId, params);
    if (response?.status === HTTP_STATUSES.ok) {
      setEnvsSnapshotsList(response?.data?.list);
    }
    setSnapshotsLoading(false);
  };

  useEffect(() => {
    if (form?.values.fromEnvId || prodEnv || envs?.[0]?.value) {
      getSnapshotsData();
    }
  }, [form?.values.fromEnvId, prodEnv]);

  const handleSnapshot = useCallback((value: any) => {
    form.setFieldValue('snapshotId', value);
    setTouched(true);
  }, []);

  const handleEnv = useCallback((value: any) => {
    form.setFieldValue('fromEnvId', value);
    setTouched(false);
  }, []);

  return (
    <ApproveModal
      btnText="Restore"
      callback={form.submitForm}
      closeModal={close}
      disabled={form.isSubmitting || !touched}
    >
      <>
        {isProdEnv ? (
          <div className={styles.editWrapper}>
            <span className={styles.modal__title}>Restore</span>
            <div className={styles.description}>
              Production environment history
            </div>
            <div style={{ marginTop: '16px' }} />
            {snapshotsLoading ? (
              <div className={styles.loading}>
                <Loading />
              </div>
            ) : null}
            {envsSnapshotsList?.length ? (
              <div className={styles.restoreContainer}>
                <div className={styles.container}>
                  {envsSnapshotsList?.length ? (
                    <RadioButton
                      customClassName={styles.snapshotItem}
                      options={envsSnapshotsList?.map((el: any) => {
                        const createdAt = formatDate(el?.createdAt);
                        return {
                          value: el?.id,
                          label: el?.createdById
                            ? 'Manual snapshot'
                            : 'Auto-snapshot',
                          additionalInfo: `${createdAt.date} ${createdAt?.time}`,
                        };
                      })}
                      onChange={handleSnapshot}
                    />
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <div className={styles.editWrapper}>
            <span className={styles.modal__title}>Restore</span>
            <div className={styles.description}>
              Select the environment from which you want to restore history
            </div>
            <span className={styles.snapshotItem} style={{ border: 'none' }}>
              Environment
            </span>
            <ToggleButtonGroup
              options={envs?.filter((el: any) => !el?.isProduction)}
              onChange={handleEnv}
              defaultValue={envId}
            />
            <div style={{ marginTop: '16px' }} />
            {snapshotsLoading ? (
              <div className={styles.loading}>
                <Loading />
              </div>
            ) : null}
            {envsSnapshotsList?.length ? (
              <div className={styles.restoreContainer}>
                <div className={styles.container}>
                  {envsSnapshotsList?.length ? (
                    <RadioButton
                      customClassName={styles.snapshotItem}
                      options={envsSnapshotsList?.map((el: any) => {
                        const createdAt = formatDate(el?.createdAt);
                        return {
                          value: el?.id,
                          label: el?.createdById
                            ? 'Manual snapshot'
                            : 'Auto-snapshot',
                          additionalInfo: `${createdAt.date} ${createdAt?.time}`,
                        };
                      })}
                      onChange={handleSnapshot}
                    />
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        )}
      </>
    </ApproveModal>
  );
};

export default Restore;
