import React from 'react';
import { IIcon } from '../../types';
import { colors } from '../../constants/colors';

const CalendarIcon = ({
  color = colors.main_font,
  onClick = undefined,
}: IIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      onClick={onClick}
    >
      <path
        d="M14.5 6.66634H2.5M11.1667 1.33301V3.99967M5.83333 1.33301V3.99967M5.7 14.6663H11.3C12.4201 14.6663 12.9801 14.6663 13.408 14.4483C13.7843 14.2566 14.0903 13.9507 14.282 13.5743C14.5 13.1465 14.5 12.5865 14.5 11.4663V5.86634C14.5 4.74623 14.5 4.18618 14.282 3.75836C14.0903 3.38203 13.7843 3.07607 13.408 2.88433C12.9801 2.66634 12.4201 2.66634 11.3 2.66634H5.7C4.57989 2.66634 4.01984 2.66634 3.59202 2.88433C3.21569 3.07607 2.90973 3.38203 2.71799 3.75836C2.5 4.18618 2.5 4.74623 2.5 5.86634V11.4663C2.5 12.5865 2.5 13.1465 2.71799 13.5743C2.90973 13.9507 3.21569 14.2566 3.59202 14.4483C4.01984 14.6663 4.57989 14.6663 5.7 14.6663Z"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CalendarIcon;
