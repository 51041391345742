// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EnvAction_btnContainer__5UWuV {
  align-items: center;
  display: flex;
  gap: 14px;
}
.EnvAction_cancelBtn__FgQrg {
  color: var(--danger);
}
.EnvAction_alternativeCancelBtn__eu7zo {
  color: var(--white);
}
.EnvAction_cancelBtn__FgQrg:hover,
.EnvAction_alternativeCancelBtn__eu7zo:hover {
  color: var(--dark-danger);
}
`, "",{"version":3,"sources":["webpack://./src/renderer/components/shared/env-actions-buttons/EnvAction.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;EACb,SAAS;AACX;AACA;EACE,oBAAoB;AACtB;AACA;EACE,mBAAmB;AACrB;AACA;;EAEE,yBAAyB;AAC3B","sourcesContent":[".btnContainer {\n  align-items: center;\n  display: flex;\n  gap: 14px;\n}\n.cancelBtn {\n  color: var(--danger);\n}\n.alternativeCancelBtn {\n  color: var(--white);\n}\n.cancelBtn:hover,\n.alternativeCancelBtn:hover {\n  color: var(--dark-danger);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnContainer": `EnvAction_btnContainer__5UWuV`,
	"cancelBtn": `EnvAction_cancelBtn__FgQrg`,
	"alternativeCancelBtn": `EnvAction_alternativeCancelBtn__eu7zo`
};
export default ___CSS_LOADER_EXPORT___;
