// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dashboard_envsContainer__u0qBj {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 4px;
  width: 100%;
}
.Dashboard_expandedWrapper__KMCok {
  width: 100%;
}
.Dashboard_tabsWrapper__1YiT6 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  width: 100%;
}
.Dashboard_tabEnv__VbBzq {
  width: 100%;
}
.Dashboard_actionIcon__\\+Kc13 {
  cursor: pointer;
}
.Dashboard_actionIcon__\\+Kc13:hover > path {
  stroke: var(--main-blue);
}
`, "",{"version":3,"sources":["webpack://./src/renderer/containers/env-dashboard/Dashboard.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qCAAqC;EACrC,QAAQ;EACR,WAAW;AACb;AACA;EACE,WAAW;AACb;AACA;EACE,aAAa;EACb,qCAAqC;EACrC,QAAQ;EACR,WAAW;AACb;AACA;EACE,WAAW;AACb;AACA;EACE,eAAe;AACjB;AACA;EACE,wBAAwB;AAC1B","sourcesContent":[".envsContainer {\n  display: grid;\n  grid-template-columns: repeat(4, 1fr);\n  gap: 4px;\n  width: 100%;\n}\n.expandedWrapper {\n  width: 100%;\n}\n.tabsWrapper {\n  display: grid;\n  grid-template-columns: repeat(4, 1fr);\n  gap: 8px;\n  width: 100%;\n}\n.tabEnv {\n  width: 100%;\n}\n.actionIcon {\n  cursor: pointer;\n}\n.actionIcon:hover > path {\n  stroke: var(--main-blue);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"envsContainer": `Dashboard_envsContainer__u0qBj`,
	"expandedWrapper": `Dashboard_expandedWrapper__KMCok`,
	"tabsWrapper": `Dashboard_tabsWrapper__1YiT6`,
	"tabEnv": `Dashboard_tabEnv__VbBzq`,
	"actionIcon": `Dashboard_actionIcon__+Kc13`
};
export default ___CSS_LOADER_EXPORT___;
